import NoDataAvailable from "../../../components/common/noDataAvailable";
import { downloadFile } from "../../../utils/site-utils";

export default function LayoutOfWarehouse({
  fileDetails,
}: Readonly<{
  fileDetails: any[];
}>) {
  if (fileDetails.length === 0) {
    return <NoDataAvailable text="No files available." />;
  }

  return (
    <div style={{ padding: "2px" }}>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {fileDetails.map((item: any) => {
          return (
            <li key={item}>
              <span
                style={{
                  cursor: "pointer",
                  color:
                    "var(--mds_brand_appearance_primary_default_text-color)",
                  textDecoration: "underline",
                }}
                onClick={() => downloadFile(item.siteId, item.filePath)}
                role="none"
              >
                {" "}
                {item.fileName}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
