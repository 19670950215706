import { HeaderContainer } from "./layout.styles";
import { signOut } from "../../utils";
import {
  McAvatar,
  McList,
  McListItem,
  McPopover,
  McTextAndIcon,
} from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import Notification from "../notification/notification";
import Box from "../common/box";
import { useContext, useState } from "react";
import { ThemeContext } from "../theme/themeImporter";
import { useNavigate } from "react-router-dom";
import { getLogoForTheme } from "../../constants/Themes";
import { getInitials } from "../../utils/utils";

export default function AppHeader({
  userName,
  role,
  shouldShowNotification,
  shouldShowSettings,
}: Readonly<{
  userName: string;
  role: string;
  shouldShowNotification: boolean;
  shouldShowSettings: boolean;
}>) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const getLogo = () => {
    return getLogoForTheme(theme);
  };

  return (
    <HeaderContainer>
      <Box display="flex" flexDirection="column">
        <Header>
          <Box style={{ display: "flex" }}>
            <Logo src={getLogo()} alt="logo" width={200} height={70} />
          </Box>
          <Box style={{ display: "flex", flexGrow: 1 }}>
            <AppNameContainer>
              Global Resilience & Intelligence tool
            </AppNameContainer>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <UtilityContainer>
              <Box>
                <McTextAndIcon icon="headset">
                  <div style={{ padding: "0px 30px 0px 10px" }}>
                    <a
                      href="https://maersk.service-now.com/end_user_portal?id=sc_cat_item&sys_id=bba864acfb8592d0c5f5fabfaeefdc3f"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      IT help
                    </a>
                  </div>
                </McTextAndIcon>
              </Box>
              {shouldShowNotification && (
                <Box>
                  <Notification />
                </Box>
              )}
              <Box>
                <div style={{ paddingTop: "10px" }}>
                  <McPopover
                    position="bottom-left"
                    fit="large"
                    arrow={false}
                    width="300px"
                    maxheight="200px"
                    open={isPopoverOpen}
                  >
                    <McAvatar
                      initials={getInitials(userName)}
                      fit="small"
                      slot="trigger"
                      style={{ cursor: "pointer" }}
                      hiddentooltip
                      appearance={"color-1"}
                      onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    />
                    <McList>
                      {shouldShowSettings && (
                        <>
                          <McListItem
                            label="Settings"
                            onClick={() => {
                              setIsPopoverOpen(false);
                              navigate("/settings");
                            }}
                          />
                          <hr />
                        </>
                      )}
                      <McListItem label="Logout" onClick={() => signOut()} />
                    </McList>
                  </McPopover>
                  <RoleText>{role}</RoleText>
                </div>
              </Box>
            </UtilityContainer>
          </Box>
        </Header>
      </Box>
    </HeaderContainer>
  );
}

const Header = styled(Box)`
  position: sticky;
  top: 4px;
  right: 0;
  z-index: 88;
  height: 70px;
  background-color: var(
    --mds_brand_appearance_neutral_default_background-color
  );
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: row;
`;

const AppNameContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: var(--mds_brand_appearance_neutral_default_on-background-color);
  line-height: 24px;
  align-self: center;
  text-transform: uppercase;
  margin-left: 35%;
  position: static;
`;

const UtilityContainer = styled(Box)`
  align-self: center;
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 10px;
`;

const Logo = styled.img`
  transform: scaleY(1.3);
`;

const RoleText = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: var(--mds_brand_appearance_neutral_text-color);
  padding-left: 10px;
`;
