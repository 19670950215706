import { useContext, useEffect, useReducer, useState } from "react";
import { toastError } from "../../../components/common/toast";
import { getExternalContact } from "../../../services/Site/ExternalContact";
import useSite from "../../../hooks/useSite";
import { getSingleSiteConfiguration } from "../../../services/SiteConfiguration";
import { ConfigurationMapping } from "../../../models/AlternativeLocation";
import {
  ExternalContactsKeys,
  ExternalContactsModel,
} from "../../../models/ExternalContacts";
import { Box } from "@maersk-global/community-react";
import { McButton, McTable } from "@maersk-global/mds-react-wrapper";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import { loadingIndicator } from "../../../components/common/loading";
import Notifications from "../../../components/common/notifications";
import Welcome from "../../Welcome";
import AddEditExternalContact from "./modals/addEditExternalContact";
import Heading from "../../../components/common/heading";
import { Button } from "../../../components/common/button";
import DeleteModal from "../../../components/common/deleteModal";
import { Cards } from "../../../components/common/cards";
import { configuration } from "../../../constants/Site";
import { ModeContext } from "../SiteBCM";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import { SiteModes } from "../../../constants/SiteBCM";
import HorizontalLine from "../../../components/common/horizontalLine";
import Action from "../../../components/common/action";
import { chunkArray } from "../../../utils/utils";

export default function ExternalContacts({
  handleStatusChange,
}: Readonly<{ handleStatusChange: () => void }>) {
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [isApplicable, setIsApplicable] = useState<boolean>(true);
  const [configurations, setConfigurations] = useState<
    ConfigurationMapping | undefined
  >(undefined);
  const [ExternalContacts, setExternalContacts] = useState<any[]>([]);
  const [ExternalContactstableData, setExternalContactsTableData] = useState<
    any[]
  >([]);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editableExternalContact, setEditableExternalContact] = useState<
    any | null
  >(null);
  const [showtable, setShowTable] = useState<boolean>(false);

  const SetShowTable = () => {
    setShowTable(!showtable);
  };

  const getExternalContacts = () => {
    getExternalContact(site.id, site.versionId)
      .then((response) => {
        if (response.length > 0) {
          response.forEach((element: ExternalContactsModel) => {
            element.isCriticalSupplier
              ? (element.criticalSupplier = "Yes")
              : (element.criticalSupplier = "No");
          });
          setExternalContactsTableData(response);
          let chunkedArray = chunkArray(response, 2);
          setExternalContacts(chunkedArray);
        } else {
          setExternalContacts([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onModalClose = () => {
    setEditableExternalContact(null);
    setIsModalOpen(false);
  };

  const onSave = () => {
    getExternalContacts();
    handleStatusChange();
  };

  const handleDelete = (id: number, path: string) => {
    onDeleteEntity(
      dispatchDelete,
      id,
      "ExternalContact",
      path,
      "externalcontacts",
      ""
    );
  };

  const externalContactsKey: ExternalContactsKeys = {
    service: "external-contact-services",
    supplierName: "supplier-name",
    primaryContactName: "primary-contact-name",
    primaryContactEmail: "primary-contact-email",
    primaryContactPhone: "primary-contact-phone",
    secondaryContactName: "secondary-contact-name",
    secondaryContactEmail: "secondary-contact-email",
    secondaryContactPhone: "secondary-contact-phone",
  };

  useEffect(() => {
    if (siteId > 0) {
      getSingleSiteConfiguration(site.id, site.versionId, "external-contacts")
        .then((response) => {
          if (response.length === 0) {
            setIsApplicable(false);
            setLoading(false);
            return;
          } else {
            let mappedResponse: ConfigurationMapping = response.reduce(
              (acc, item) => {
                return {
                  ...acc,
                  [item.key]: item.configurationValue,
                };
              },
              {}
            );
            setConfigurations(mappedResponse);
            getExternalContacts();
          }
        })
        .catch((error) => {
          toastError(error.message);
        });
    }
  }, [siteId]);

  const onAddNewExternalContactClick = () => {
    setIsModalOpen(true);
  };

  const constructActionItems = (row: any) => {
    let items = [];

    mode === SiteModes.Write &&
      items.push({
        label: "Edit",
        onClick: () => {
          setIsModalOpen(true);
          setEditableExternalContact(row);
        },
      });

    mode === SiteModes.Write &&
      !row.isConfiguredArchetypeLevel &&
      items.push({
        label: "Delete",
        onClick: () => handleDelete(row.id, row.serviceName),
      });

    return items;
  };

  const displayTable = () => {
    return (
      <>
        <br />
        <McTable
          disableroundedcorners
          data={ExternalContactstableData}
          expand
          columns={[
            { id: "serviceName", label: "Description" },
            { id: "criticalSupplier", label: "Critical Supplier" },
            { id: "supplierName", label: "Supplier Name" },
            { id: "primaryContactName", label: "Primary Contact Name" },
            { id: "primaryContactEmail", label: "Primary Contact Email" },
            { id: "primaryContactPhone", label: "Primary Contact Phone" },
            { id: "secondaryContactName", label: "Secondary Contact Name" },
            { id: "secondaryContactEmail", label: "Secondary Contact Email" },
            { id: "secondaryContactPhone", label: "Secondary Contact Phone" },
            { id: "id", label: "", sortDisabled: true },
          ]}
          fit="small"
        >
          {ExternalContactstableData.map((row: any) => (
            <>
              <div key={`${row.id}_id`} slot={`${row.id}_id`}>
                <div style={{ float: "right" }}>
                  {mode === SiteModes.Write ? (
                    <Action items={constructActionItems(row)} />
                  ) : null}
                </div>
              </div>
            </>
          ))}
        </McTable>
      </>
    );
  };

  const displayasCard = () => {
    return (
      <>
        {ExternalContacts.map((chunk: any, chunkIndex: number) => (
          <Box
            key={chunkIndex}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="10px"
          >
            {chunk.map((externalContact: ExternalContactsModel) => (
              <Box
                key={externalContact.id}
                style={{
                  padding: "10px",
                  border: "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
                  boxSizing: "border-box",
                  height: "auto",
                  overflow: "auto",
                  margin: "8px",
                }}
              >
                <Cards
                  items={[
                    { label: "", value: externalContact.supplierName },
                    {
                      label: "",
                      value: `Critical Supplier : ${
                        externalContact.isCriticalSupplier ? "Yes" : "No"
                      }`,
                    },
                    null,
                    { label: "", value: externalContact.primaryContactName },
                    { label: "", value: externalContact.primaryContactEmail },
                    { label: "", value: externalContact.primaryContactPhone },
                    null,
                    { label: "", value: externalContact.secondaryContactName },
                    { label: "", value: externalContact.secondaryContactEmail },
                    { label: "", value: externalContact.secondaryContactPhone },
                  ]}
                  key={externalContact.id}
                  id={externalContact.id}
                  title={externalContact.serviceName}
                  allowEdit={mode === SiteModes.Write}
                  allowDelete={
                    mode === SiteModes.Write &&
                    !externalContact.isConfiguredArchetypeLevel
                  }
                  displayWarning={
                    externalContact.isConfiguredArchetypeLevel &&
                    !externalContact.isContactDetailsFilled &&
                    externalContact.configurationValue ===
                      configuration.Mandatory
                  }
                  onDelete={handleDelete}
                  onEdit={() => {
                    setIsModalOpen(true);
                    setEditableExternalContact(externalContact);
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  };

  const renderWelcome = () => {
    return (
      <>
        <Welcome
          heading={"External Contacts"}
          subHeading={
            "There’s no external contacts for the site. Start adding one."
          }
          buttonLabel={"Add New External Contact"}
          icon="plus"
          onButtonClick={onAddNewExternalContactClick}
          isDisabled={mode === SiteModes.Read}
        />
      </>
    );
  };

  const renderExternalConatcs = () => {
    return (
      <>
        <SiteElementsRenderer mode={mode}>
          <>
            <Heading heading={""} subheading={""}>
              <Button
                click={onAddNewExternalContactClick}
                label="Add New External Contact"
                fit="medium"
              />
            </Heading>
            <HorizontalLine />
          </>
        </SiteElementsRenderer>

        <Heading heading={""} subheading={""}>
          <McButton
            label={showtable ? "Show as Cards" : "Show as Table"}
            appearance="neutral"
            variant="outlined"
            fit="small"
            click={SetShowTable}
          ></McButton>
        </Heading>

        {!showtable ? displayasCard() : displayTable()}
      </>
    );
  };

  if (loading) return loadingIndicator;

  // IF NOT APPLICABLE SHOW WARNING
  if (!isApplicable) {
    return (
      <Notifications
        description={[
          "This section is not applicable for your site.",
        ]}
        variant="warning"
      />
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
          handleStatusChange();
        }}
      />
      <AddEditExternalContact
        isOpen={isModalOpen}
        editableExternalContact={editableExternalContact}
        configurations={configurations}
        keys={externalContactsKey}
        onClose={onModalClose}
        onSave={onSave}
      />
      {ExternalContacts.length == 0 ? renderWelcome() : renderExternalConatcs()}
    </>
  );
}
