import React, { useEffect } from "react";
import styled from "styled-components";
import Info from "./info";
import SiteComments from "../../pages/Site/SiteComments";

/**
 * `SiteElementLayout` is a React functional component designed to provide a consistent layout for site elements. It features a styled container that includes a heading and a body section for additional content. The component utilizes styled-components for custom styling and leverages theme colors and fonts defined in the project's styles directory.

 * Props:
 * - `heading`: string - The text to be displayed as the heading of the site element.
 * - `children`: React.ReactNode - The content to be displayed in the body section of the site element. This can include any React nodes or components.

 * The component structure is as follows:
 * - A `SiteElementLayoutWrapper` styled div that acts as the outer container for the site element. It applies a border, padding, and margin to create a distinct and visually appealing box.
 * - Inside the `SiteElementLayoutWrapper`, there are two main parts:
 *   - A `span` with the class `headingText` that displays the `heading` prop. The text styling for this heading is defined within the `SiteElementLayoutWrapper` styled component, using the `maerskHeadlineFontFamily` and colors from the project's style guide.
 *   - A `div` with the class `bodyWrapper` that serves as a container for the `children` prop. This allows for flexible content to be inserted below the heading.

 * Usage Example:
 * ```tsx
 * <SiteElementLayout heading="Section Title">
 *   <p>This is some content that will be displayed in the body section.</p>
 * </SiteElementLayout>
 * ```

 * Note: This component is designed to be reusable across different parts of the site, providing a uniform look and feel for various site elements. The styling is customizable via the project's global styles.
 */
export default function SiteElementLayout({
  heading,
  sectionId,
  shouldShowComments = true,
  children,
  popoverContent = null,
}: Readonly<{
  heading: string;
  sectionId?: number;
  shouldShowComments?: boolean;
  children: React.ReactNode;
  popoverContent?: React.ReactNode;
}>) {
  const [commentsSectionId, setCommentsSectionId] = React.useState<number>(
    sectionId ?? 0
  );

  useEffect(() => {
    if (sectionId) {
      setCommentsSectionId(sectionId);
    }
  }, [sectionId]);
  return (
    <SiteElementLayoutWrapper>
      <div className="topWrapper">
        <span className="headingText">{heading}</span>
        <div className="popoverdiv">
          {popoverContent && (
            <Info popoverContent={popoverContent} tooltipText={null}>
              <span>Info</span>
            </Info>
          )}
        </div>
        <div className="rightWrapper">
          {shouldShowComments && <SiteComments sectionId={commentsSectionId} />}
        </div>
      </div>
      <div className="bodyWrapper">{children}</div>
    </SiteElementLayoutWrapper>
  );
}

const SiteElementLayoutWrapper = styled.div`
  border: 1px solid var(--mds_brand_appearance_neutral_weak_border-color);
  padding: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;

  .topWrapper {
    display: flex;
    align-items: center;
  }

  .headingText {
    font-family: var(--mds_brand_typography_text_font-family);
    color: var(--mds_brand_appearance_neutral_default_text-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  .popoverdiv {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .rightWrapper {
    display: flex;
    align-items: center;
  }

  .bodyWrapper {
    margin-top: 10px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Optional: Add some space below the header */
  }
`;
