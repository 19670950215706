import { useEffect, useState } from "react";
import SettingsCategories from "./SettingsCategories";
import Themes from "./Themes";

const settingsCategories = [
  {
    name: "Theme",
    isSelected: true,
    code: "theme",
  },
];

export default function Settings() {
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  useEffect(() => {
    setSelectedCategory(settingsCategories[0].code);
  }, []);

  const onSelectCategory = (code: string) => {
    setSelectedCategory(code);
  };

  return (
    <>
      <div className="site-heading">Settings</div>
      <div style={{ display: "grid", gridTemplateColumns: "0.5fr 1fr" }}>
        <SettingsCategories
          masterCategories={settingsCategories}
          onSelectCategory={onSelectCategory}
        />
        <div style={{ marginLeft: "20px", padding: "0 0 20px 0" }}>
          <div style={{ paddingTop: "10px" }}>
            {selectedCategory === "theme" && <Themes />}
          </div>
        </div>
      </div>
    </>
  );
}
