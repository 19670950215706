export const processEditableTableActions = {
  SET_IMPACTS: "SET_IMPACTS",
  SET_PROCESS_EDIT_ENABLED: "SET_PROCESS_EDIT_ENABLED",
  SET_PROCESS_EDIT_DISABLED: "SET_PROCESS_EDIT_DISABLED",
  IMPACTS_DROPDOWN_CHANGE: "IMPACTS_DROPDOWN_CHANGE",
  CRITICALITY_DROPDOWN_CHANGE: "CRITICALITY_DROPDOWN_CHANGE",
  SET_PROCESSES: "SET_PROCESSES",
  ON_PROCESS_ENABLED_DISABLED: "ON_PROCESS_ENABLED_DISABLED",
};

export const defaultprocessEditableTableState = {
  tableColumns: [],
  impacts: [],
  impactsDropdown: [],
  criticalityDropdown: [],
  processes: [],
  processesCopy: [],
  isProcessEditEnabled: false,
  impactCriticalityRTO: 0,
  highestIdTimeInterval: 0,
  timeInterval: [],
};

export const processEditableTableReducer = (state: any, action: any) => {
  switch (action.type) {
    case processEditableTableActions.SET_PROCESS_EDIT_ENABLED:
      return {
        ...state,
        isProcessEditEnabled: true,
      };
    case processEditableTableActions.SET_PROCESS_EDIT_DISABLED:
    {
      let processesCopyForEdit = state.processesCopy;
      return {
        ...state,
        processes: JSON.parse(JSON.stringify(processesCopyForEdit)),
        isProcessEditEnabled: false,
      };
    }
    case processEditableTableActions.SET_PROCESSES: {
      let setProcesses = action.processes;
      let { impact, timeInterval, criticality, criticalityRTO } =
        action.impacts;
      let highestIdTimeInterval = timeInterval[timeInterval.length - 1].name;
      let impactsDropdown = impact.map((impact: any) => {
        return {
          id: impact.id,
          label: impact.name,
          value: impact.name,
        };
      });
      let criticalityDropdown = criticality.map((criticality: any) => {
        return {
          id: criticality.id,
          label: criticality.name,
          value: criticality.rank,
        };
      });

      let criticalityRtoRank = criticalityDropdown.find((item: any) => {
        return item.id === criticalityRTO;
      });

      let impactCriticalityRTO = criticalityRtoRank.value;
      let mappedProcess = reduceProcess(
        setProcesses,
        timeInterval,
        criticalityDropdown,
        impactsDropdown
      );
      let processesCopy = JSON.parse(JSON.stringify(mappedProcess));
      return {
        ...state,
        impacts: action.impacts,
        impactsDropdown: impactsDropdown,
        processes: mappedProcess,
        criticalityDropdown: criticalityDropdown,
        processesCopy: processesCopy,
        impactCriticalityRTO: impactCriticalityRTO,
        highestIdTimeInterval: highestIdTimeInterval,
        isProcessEditEnabled: action.isProcessEditEnabled,
        timeInterval: timeInterval,
      };
    }
    case processEditableTableActions.IMPACTS_DROPDOWN_CHANGE: {
      let processesForDropdown = state.processes;
      processesForDropdown[action.index].selectedImpact = action.impact;
      return {
        ...state,
        processes: processesForDropdown,
      };
    }
    case processEditableTableActions.CRITICALITY_DROPDOWN_CHANGE: {
      let processesForCriticality = state.processes;
      let processIndex = action.index;
      let processColumn = action.column;
      let timeIntervalForImpacts = state.impacts.timeInterval;

      let currentProcess = processesForCriticality[processIndex];

      currentProcess[processColumn].selectedCriticality = action.criticality;

      mapProcessOnCriticalityDropdownChange(
        timeIntervalForImpacts,
        currentProcess[processColumn].timeIntervalOrder,
        currentProcess,
        state.criticalityDropdown,
        action.criticality.value
      );

      currentProcess.rto = calculateProcessRTO(
        currentProcess,
        timeIntervalForImpacts,
        state.impactCriticalityRTO,
        state.highestIdTimeInterval
      );

      return {
        ...state,
        processes: processesForCriticality,
      };
    }
    case processEditableTableActions.ON_PROCESS_ENABLED_DISABLED: {
      let processesForEnabledDisabled = state.processes;
      let index = action.index;
      let selectedValue = action.value;
      processesForEnabledDisabled[index].isdisabled = !selectedValue;

      return {
        ...state,
        processes: processesForEnabledDisabled,
        processesCopy: processesForEnabledDisabled,
      };
    }
  }
};

const reduceProcess = (
  processes: any,
  timeInterval: any,
  criticalityDropdown: any,
  impactsDropDown: any
) => {
  let mappedProcess = processes.map((process: any) => {
    let prevSelectedCriticalityId = 0;
    timeInterval.map((interval: any) => {
      let timeIntervalCriticality = process.timeIntervalCriticalities.find(
        (item: any) => item?.timeInterval?.id === interval.id
      );
      if (!timeIntervalCriticality?.criticality) {
        process[interval.name] = {
          criticality: "",
          criticalityDropdown: criticalityDropdown,
          selectedCriticality: null,
          variant: "",
          timeIntervalId: interval.id,
          timeIntervalOrder: interval.order,
        };
      } else {
        let criticality = timeIntervalCriticality.criticality;
        let fiteredCriticalityDropdown = criticalityDropdown.filter(
          (item: any) => item.value >= prevSelectedCriticalityId
        );
        process[interval.name] = {
          criticality: criticality.name,
          criticalityDropdown: fiteredCriticalityDropdown,
          selectedCriticality:
            criticality.id > 0
              ? {
                  id: criticality.id,
                  label: criticality.name,
                  value: criticality.rank,
                }
              : null,
          variant: criticality.colorCode,
          timeIntervalId: interval.id,
          timeIntervalOrder: interval.order,
        };
        prevSelectedCriticalityId = criticality.rank;
      }
    });
    process.selectedImpact = impactsDropDown.filter((item: any) =>
      process.impacts.includes(item.id)
    );
    return process;
  });
  return mappedProcess;
};

const mapProcessOnCriticalityDropdownChange = (
  timeInterval: any,
  timeIntervalOrder: any,
  process: any,
  criticalityDropdown: any,
  rank: any
) => {
  let timeIntervals = timeInterval.filter(
    (interval: any) => interval.order > timeIntervalOrder
  );
  timeIntervals.map((interval: any) => {
    let key = process[interval.name];

    key.criticalityDropdown = criticalityDropdown.filter(
      (item: any) => item.value >= rank
    );
    if (key.criticalityDropdown.length === 1) {
      key.selectedCriticality = {
        id: key.criticalityDropdown[0].id,
        label: key.criticalityDropdown[0].label,
        value: key.criticalityDropdown[0].value,
      };
    } else {
      key.selectedCriticality = null;
    }
  });
  return process;
};

const calculateProcessRTO = (
  process: any,
  timeInterval: any,
  impactCriticalityRTO: number,
  highestIdTimeInterval: number
) => {
  let rto = "";
  let timeIntervalWithHigherCriticality = "";

  for (let interval of timeInterval) {
    let key = interval.name;
    let selectedCriticality = process[key].selectedCriticality;
    if (selectedCriticality) {
      if (selectedCriticality?.value === impactCriticalityRTO) {
        rto = `< ${key}`;
        break;
      } else if (
        selectedCriticality?.value > impactCriticalityRTO &&
        timeIntervalWithHigherCriticality === ""
      ) {
        timeIntervalWithHigherCriticality = key;
      }
    }
  }

  if (rto === "") {
    if (timeIntervalWithHigherCriticality === "") {
      rto = `> ${highestIdTimeInterval}`;
    } else {
      rto = `< ${timeIntervalWithHigherCriticality}`;
    }
  }
  return rto;
};
