import MultiSelect from "@maersk-global/community-react-multi-select";
import Select from "@maersk-global/community-react-select";
import { useEffect, useReducer, useState } from "react";
import {
  defaultprocessEditableTableState,
  processEditableTableActions,
  processEditableTableReducer,
} from "./reducers/editableProcessTable";
import { StyledTag } from "../../components/common/common.styles";
import {
  ProcessImpact,
  ProcessImpactConfiguration,
  TimeIntervalCriticality,
} from "../../models/Processes";
import ActionBox from "../../components/common/actionBox";
import { DeleteIcon, StaticIcon } from "../../components/common/icons";
import Tooltip from "../../components/common/tooltip";
import { McInput, McSwitch } from "@maersk-global/mds-react-wrapper";
import Box from "../../components/common/box";
import styled from "styled-components";
import { configureProcessSelection } from "../../services/Site/Impacts";
import { toast } from "../../components/common/toast";
import { Table } from "@maersk-global/community-react";

export default function EditableProcessTable(props: any) {
  const [state, dispatch] = useReducer(
    processEditableTableReducer,
    defaultprocessEditableTableState
  );

  const [isProcessEditEnabled, setIsProcessEditEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch({
      type: processEditableTableActions.SET_PROCESSES,
      processes: props.processes,
      impacts: props.impacts,
    });
  }, [props.processes]);

  useEffect(() => {
    setIsProcessEditEnabled(props.isProcessEditEnabled);
  }, [props.isProcessEditEnabled]);

  const mapTableColumnsWithData = (process: any) => {
    let result: { [key: string]: JSX.Element } = {};
    state.timeInterval.forEach((item: any) => {
      let key = item.name;
      result[key] = (
        <StyledTag
          bgColor={
            !process.isdisabled ? process[key].variant : "rgb(247, 247, 247)"
          }
        >
          {process[key].criticality}
        </StyledTag>
      );
    });

    return result;
  };

  const mapTableColumnsWithEditableData = (process: any, index: number) => {
    let result: { [key: string]: JSX.Element } = {};
    props.tableColumns.forEach((column: any) => {
      result[column.id] = (
        <Select
          className="react-select"
          closeMenuOnSelect
          fit="small"
          id={`${process.id} - ${process[column.id]}`}
          options={process[column.id].criticalityDropdown}
          variant="default"
          name={`${process.id} - ${process[column.id]}`}
          menuPortalTarget={document.body}
          value={process[column.id].selectedCriticality}
          renderValue="label"
          disabled={process.isdisabled}
          onChange={(newValue: any, action?: any) => {
            dispatch({
              type: processEditableTableActions.CRITICALITY_DROPDOWN_CHANGE,
              criticality: newValue,
              index: index,
              column: column.id,
            });
          }}
        />
      );
    });
    return result;
  };

  const handleProcessDelete = (
    processId: any,
    isProcess: any,
    processName: any
  ) => {
    props.onDeleteClick(processId, isProcess, processName, "");
  };

  const handleProcessEnablement = (
    processId: any,
    index: number,
    value: any
  ) => {
    dispatch({
      type: processEditableTableActions.ON_PROCESS_ENABLED_DISABLED,
      value: value,
      index: index,
    });

    if (!props.isProcessEditEnabled) {
      configureProcessSelection({
        processId: processId,
        isdisabled: !value,
        doesSubProcessExist: !props.isProcess,
      })
        .then((res) => {})
        .catch((error) => {
          toast("error", "Failed to select process for the site", "error");
          dispatch({
            type: processEditableTableActions.ON_PROCESS_ENABLED_DISABLED,
            value: !value,
            index: index,
          });
        });
    }
  };

  const mapProcessData = (processes: any) => {
    return !isProcessEditEnabled
      ? processes.map((process: any, index: number) => {
          return {
            name: !process.isConfiguredArchetypeLevel ? (
              process.name
            ) : (
              <McSwitch
                change={(e: CustomEvent) => {
                  handleProcessEnablement(process.id, index, e.detail);
                }}
                checked={!process.isdisabled}
                disabled={props.isReadOnly}
              >
                <div
                  slot="label"
                  className="label"
                  style={{
                    color: process.isdisabled
                      ? "var(--mds_brand_appearance_neutral_weak_border-color)"
                      : "",
                  }}
                >
                  {process.name}
                </div>
              </McSwitch>
            ),
            impactType: (
              <Box display="flex">
                <Box display="flex">
                  <span
                    style={{
                      color: process.isdisabled
                        ? "var(--mds_brand_appearance_neutral_weak_border-color)"
                        : "",
                    }}
                  >
                    {process.selectedImpact?.length ?? 0} of{" "}
                    {state.impactsDropdown.length} selected
                  </span>
                  {process.selectedImpact?.length > 0 && (
                    <Tooltip
                      content={process.selectedImpact
                        .map((item: any) => item.value)
                        .join(",")}
                    >
                      <StaticIcon
                        icon="chevron-down"
                        slot="trigger"
                        color={
                          process.isdisabled
                            ? "var(--mds_brand_appearance_neutral_weak_border-color)"
                            : ""
                        }
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            ),
            rto: !process.isConfiguredArchetypeLevel ? (
              process.rto
            ) : (
              <div
                style={{
                  color: process.isdisabled
                    ? "var(--mds_brand_appearance_neutral_weak_border-color)"
                    : "",
                }}
              >
                {process.rto}
              </div>
            ),
            id: (
              <>
                {!process.isConfiguredArchetypeLevel && !props.isReadOnly ? (
                  <DeleteIcon
                    onClick={() =>
                      handleProcessDelete(
                        process.id,
                        props.isProcess,
                        process.name
                      )
                    }
                  />
                ) : null}
              </>
            ),
            ...mapTableColumnsWithData(process),
          };
        })
      : processes.map((process: any, index: number) => {
          return {
            ...mapTableColumnsWithEditableData(process, index),
            name: !process.isConfiguredArchetypeLevel ? (
              process.name
            ) : (
              <McSwitch
                change={(e: CustomEvent) => {
                  handleProcessEnablement(process.id, index, e.detail);
                }}
                checked={!process.isdisabled}
              >
                <div
                  slot="label"
                  className="label"
                  style={{
                    color: process.isdisabled
                      ? "var(--mds_brand_appearance_neutral_weak_border-color)"
                      : "",
                  }}
                >
                  {process.name}
                </div>
              </McSwitch>
            ),
            impactType: (
              <MultiSelect
                className="react-select"
                closeMenuOnSelect
                fit="small"
                id="selectImpact"
                name="selectImpact"
                options={state.impactsDropdown}
                renderValue="label"
                onChange={(newValue: any, action?: any) =>
                  dispatch({
                    type: processEditableTableActions.IMPACTS_DROPDOWN_CHANGE,
                    impact: newValue,
                    index: index,
                  })
                }
                menuPortalTarget={document.body}
                orientation="vertical"
                suggestType="static"
                value={process.selectedImpact}
                variant="checkbox"
                disabled={process.isdisabled}
              />
            ),
            rto: (
              <McInput
                fit="small"
                id="rto"
                type="text"
                value={process.rto}
                variant="default"
                key={process.id}
                disabled={true}
                label=""
              />
            ),
            id: "",
          };
        });
  };

  const saveProcessImpactConfiguration = () => {
    let criticalityRtoId = state.criticalityDropdown.find(
      (c: any) => c.value === state.impactCriticalityRTO
    )?.id;
    let mappedProcesses: ProcessImpact[] = state.processes.map(
      (process: any) => {
        let selectedImpactIds = [];

        if (process.selectedImpact) {
          selectedImpactIds = process.selectedImpact.map(
            (item: any) => item.id
          );
        }
        const timeIntervalCriticalities: TimeIntervalCriticality[] =
          state.timeInterval.map((item: any) => {
            return {
              timeIntervalId: item.id,
              criticalityId: process[item.name].selectedCriticality?.id,
            };
          });
        let processId = 0;
        let subProcessId = 0;
        let subProcessName = "";
        let processName = "";

        if (!props.isProcess) {
          processId = props.processId;
          subProcessId = process.id;
          subProcessName = process.name;
        } else {
          processId = process.id;
          processName = process.name;
        }

        const processImpact: ProcessImpact = {
          processId: processId,
          processName: processName,
          subProcessName: subProcessName,
          subProcessId: subProcessId,
          impacts: selectedImpactIds,
          rto: process.rto,
          timeIntervalCriticalities: timeIntervalCriticalities,
          criticalityRtoId: criticalityRtoId,
          isdisabled: process.isdisabled ?? false,
        };

        return processImpact;
      }
    );

    const processImpactMappings: ProcessImpactConfiguration = {
      processImpacts: mappedProcesses,
    };

    props.onConfigureProcessImpact(
      processImpactMappings,
      props.isProcess,
      props.processId
    );
  };

  return (
    <ExpandableTableWrapper>
      <Table
        columnData={[
          {
            accessorKey: "name",
            header: props.isProcess ? "Processes" : "Subprocess",
            id: "name",
            enableSorting: false,
            meta: {
              type: "custom",
              stickyColumn: true,
            } as any,
            size: 220,
          },
          {
            accessorKey: "timeBasedImpact",
            columns: [
              ...props.tableColumns,
              {
                accessorKey: "rto",
                header: "RTO",
                id: "rto",
                enableSorting: false,
                meta: {
                  type: "custom",
                },
              },
            ],
            enableSorting: false,
            header: "Time based Impact/Criticality",
            id: "timeBasedImpact",
            size: props.tableColumns.length * 110 + 110,
            minSize: props.tableColumns.length * 110 + 110,
            maxSize: props.tableColumns.length * 110 + 220,
          },
          {
            accessorKey: "impactType",
            enableSorting: false,
            header: "Impact type",
            id: "impactType",
            meta: {
              type: "custom",
              stickyColumn: true,
            } as any,
            size: 250,
            minSize: 200,
            maxSize: 300,
          },
          {
            accessorKey: "id",
            header: "",
            id: "id",
            enableSorting: false,
            meta: {
              type: "custom",
              align: "center",
              stickyColumn: true,
            },
            size: 20,
            minSize: 20,
            maxSize: 20,
          },
        ]}
        defaultData={mapProcessData(state.processes)}
        key={"process_impact_table"}
        gridLine="both"
        className="editableTableProcessImpact"
        state={{
          columnPinning: {
            left: ["name"],
            right: ["impactType", "id"],
          },
        }}
      />
      {isProcessEditEnabled && (
        <ActionBox
          onCancel={() => {
            dispatch({
              type: processEditableTableActions.SET_PROCESS_EDIT_DISABLED,
            });
            props.onEditCancelClick();
          }}
          onSave={saveProcessImpactConfiguration}
          shouldShowCancelButton={true}
        />
      )}
    </ExpandableTableWrapper>
  );
}

const ExpandableTableWrapper = styled.div`
  display: grid;
  overflow: auto;
`;
