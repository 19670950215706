import { replacePlaceholder } from "../../utils/utils";
import "./styles/notification.scss";

export default function NotificationItem({
  notification,
}: Readonly<{
  notification: any;
}>) {
  const calculateElapsedTime = (date: string) => {
    const currentDate = new Date();
    const createdAt = new Date(date);
    const elapsedTime = currentDate.getTime() - createdAt.getTime();
    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    switch (true) {
      case seconds < 60:
        return "a few seconds ago";
      case minutes < 60:
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      case hours < 24:
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      case days < 30:
        return `${days} day${days > 1 ? "s" : ""} ago`;
      case months < 12:
        return `${months} month${months > 1 ? "s" : ""} ago`;
      default:
        return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <button
      className="notification-item"
      key={notification.createdAt}
      onClick={() => {
        if (notification.url) {
          window.location.href = `/${notification.url}`;
        }
      }}
      tabIndex={0}
    >
      <strong>{notification.title}</strong>
      <div
        dangerouslySetInnerHTML={{
          __html: replacePlaceholder(notification.message),
        }}
        style={{ paddingTop: "5px" }}
      ></div>
      <div className="mds-text--x-small-normal" style={{ paddingTop: "5px" }}>
        {calculateElapsedTime(notification.createdAt)}
      </div>
    </button>
  );
}
