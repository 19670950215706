import React, { useEffect, useReducer, useState } from "react";
import CommonModal from "../../../../components/common/commonModal";
import {
  InternalContactsKeys,
  SaveInternalContactsModel,
  userKind,
} from "../../../../models/InternalContacts";
import { ConfigurationMapping } from "../../../../models/AlternativeLocation";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import { configuration } from "../../../../constants/Site";
import {
  McInput,
  McRadioGroup,
  McRadio,
  McCheckboxGroup,
  McCheckbox,
} from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../../reducers/error";
import useSite from "../../../../hooks/useSite";
import { saveInternalContact } from "../../../../services/Site/InternalContacts";
import { toastSuccess } from "../../../../components/common/toast";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../utils/validation-utils";
import { trimStringsBeforeSave } from "../../../../utils/utils";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";

const defaultInternalContacts: SaveInternalContactsModel = {
  siteId: 0,
  versionId: 0,
  id: 0,
  displayName: "",
  email: "",
  mobilePhone: "",
  jobTitle: "",
  brRole: "",
  isPrimary: false,
  isSecondary: false,
  isIncidentResponseTeam: false,
  isAzureContact: false,
  userType: "",
};

export default function AddEditInternalContact({
  isOpen,
  editableInternalContact,
  configurations,
  keys,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableInternalContact: any;
  configurations: ConfigurationMapping | undefined;
  keys: InternalContactsKeys;
  onClose: () => void;
  onSave: () => void;
}>) {
  const { site } = useSite();
  const [editableInternalContacts, setEditableInternalContacts] =
    useState<SaveInternalContactsModel>(
      editableInternalContact || defaultInternalContacts
    );
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);

  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    if (editableInternalContact != null) {
      setEditableInternalContacts(editableInternalContact);
    } else {
      setEditableInternalContacts({
        ...defaultInternalContacts,
        siteId: site.id,
        versionId: site.versionId,
      });
    }
  }, [isOpen]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEditableInternalContacts({
      ...editableInternalContacts,
      [name]: value,
    });
  };

  const handleCheckboxChange = (name: string, value: boolean) => {
    setEditableInternalContacts({
      ...editableInternalContacts,
      [name]: value,
    });
  };

  const handleRoleTypeChange = (name: string, value: string) => {
    setEditableInternalContacts({
      ...editableInternalContacts,
      isPrimary: value === userKind.primary,
      isSecondary: value === userKind.secondary,
    });
  };

  const onModalClose = () => {
    onClose();
  };

  const isMandatory = (key: string): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  const validateInternalContacts = () => {
    const errors: string[] = [];
    let { displayName, email, mobilePhone, jobTitle } =
      editableInternalContacts;

    if (isMandatory(keys.name) && !displayName) {
      errors.push("Name is required");
    }
    if (isMandatory(keys.jobTitle) && !jobTitle) {
      errors.push("Job Title is required");
    }
    if (isMandatory(keys.email) && !email) {
      errors.push("Email is required");
    }
    if (isMandatory(keys.phone) && !mobilePhone) {
      errors.push("Phone is required");
    }
    if (mobilePhone && !validatePhoneNumber(mobilePhone?.trim())) {
      errors.push("Phone number is invalid");
    }
    if (email && !validateEmail(email?.trim())) {
      errors.push("Email is invalid");
    }
    return errors;
  };

  const addOrUpdateInternalContacts = () => {
    editableInternalContacts.userType = "2"; // Site User
    const errors = validateInternalContacts();
    if (errors.length > 0) {
      dispatchErrors({ type: errorActions.ADD_ERROR, errorMessage: errors });
      return;
    }

    saveInternalContact(trimStringsBeforeSave(editableInternalContacts))
      .then((response) => {
        toastSuccess("Internal Contact saved successfully");
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  return (
    <div>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={addOrUpdateInternalContacts}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={onModalClose}
        isOpen={isOpen}
        onModalClose={onModalClose}
        heading={"Add/Edit Internal Contact"}
        dimension="medium"
        errors={errors}
      >
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.name}
        >
          <McInput
            label=""
            fit="small"
            value={editableInternalContacts.displayName}
            input={(e: any) => handleInputChange(e)}
            name="displayName"
            disabled={editableInternalContacts.isAzureContact}
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Name"
              isMandatory={isMandatory(keys.name)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.jobTitle}
        >
          <McInput
            label="Job Title"
            fit="small"
            value={editableInternalContacts.jobTitle}
            input={(e: any) => handleInputChange(e)}
            name="jobTitle"
            disabled={editableInternalContacts.isAzureContact}
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Job Title"
              isMandatory={isMandatory(keys.jobTitle)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.email}
        >
          <McInput
            label="Email"
            fit="small"
            value={editableInternalContacts.email}
            input={(e: any) => handleInputChange(e)}
            name="email"
            disabled={editableInternalContacts.isAzureContact}
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Email"
              isMandatory={isMandatory(keys.email)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.phone}
        >
          <McInput
            label=""
            fit="small"
            value={editableInternalContacts.mobilePhone}
            input={(e: any) => handleInputChange(e)}
            name="mobilePhone"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Phone"
              isMandatory={isMandatory(keys.phone)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.roleType}
        >
          <McCheckboxGroup
            name="selectedUserKind"
            legend=""
            orientation="horizontal"
            onChange={(e: any) =>
              handleRoleTypeChange(e.target.name, e.target.value)
            }
          >
            <McCheckbox
              name="isPrimary"
              value={userKind.primary}
              label="Primary"
              checked={editableInternalContacts.isPrimary}
              disabled={editableInternalContacts.isAzureContact}
            />
            <McCheckbox
              name="isSecondary"
              value={userKind.secondary}
              label="Secondary"
              checked={editableInternalContacts.isSecondary}
              disabled={editableInternalContacts.isAzureContact}
            />
            <SiteMandatoryLabel
              slot="legend"
              label="BR Role Type"
              isMandatory={isMandatory(keys.roleType)}
            />
          </McCheckboxGroup>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.incidentResponseTeam}
        >
          <RadioGroupWrapper>
            <McRadioGroup
              legend=""
              name="isIncidentResponseTeam"
              orientation="horizontal"
              onClick={(e: any) =>
                handleCheckboxChange(
                  e.target.name,
                  e.target.value === "yes" ? true : false
                )
              }
            >
              <McRadio
                name="isIncidentResponseTeam"
                label="Yes"
                value="yes"
                checked={editableInternalContacts.isIncidentResponseTeam}
              />
              <McRadio
                name="isIncidentResponseTeam"
                label="No"
                value="no"
                checked={!editableInternalContacts.isIncidentResponseTeam}
              />
              <SiteMandatoryLabel
                slot="legend"
                label="Incident Response Team"
                isMandatory={isMandatory(keys.incidentResponseTeam)}
              />
            </McRadioGroup>
          </RadioGroupWrapper>
        </ConfigurableComponentsRenderer>
      </CommonModal>
    </div>
  );
}

const RadioGroupWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
