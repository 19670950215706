import SiteElementLayout from "../../components/common/siteElementLayout";
import { Site } from "../../models/Site";
import { getSite } from "../../services/Sites";
import ExpandedTableContent from "../../components/common/expandedTableContent";
import { toast } from "../../components/common/toast";
import { loadingIndicator } from "../../components/common/loading";
import Box from "../../components/common/box";
import useSite from "../../hooks/useSite";
import { useEffect, useState } from "react";

export default function SiteDetails() {
  const [loading, setLoading] = useState<boolean>(true);
  const [siteDetails, setSiteDetails] = useState<Site>();

  const { siteId, site } = useSite();

  useEffect(() => {
    if (siteId > 0) {
      getSite(site.id)
        .then((data) => {
          setSiteDetails(data);
          setLoading(false);
        })
        .catch((error) => {
          toast("error", error.message, "error");
        });
    }
  }, [siteId]);
  return (
    <SiteElementLayout heading="Site Details" shouldShowComments={false}>
      {loading ? (
        loadingIndicator
      ) : (
        <>
          <Box
            display="grid"
            gridTemplateColumns="0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr"
            gridGap="20px"
          >
            <Box>
              <ExpandedTableContent
                title="Site ID"
                value={siteDetails?.code ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Region"
                value={siteDetails?.regionName ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Area"
                value={siteDetails?.areaName ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Country"
                value={siteDetails?.country ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Entity"
                value={siteDetails?.entity ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Site type"
                value={siteDetails?.siteTypeName ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Archetype"
                value={siteDetails?.archetypeName ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Brand"
                value={siteDetails?.brandName ?? ""}
              />
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="0.5fr 3.5fr">
            <Box>
              <ExpandedTableContent
                title="Site Name"
                value={siteDetails?.siteName ?? ""}
              />
            </Box>
            <Box>
              <ExpandedTableContent
                title="Address"
                value={`${siteDetails?.address}, ${siteDetails?.city}, ${siteDetails?.postalCode}`}
              />
            </Box>
          </Box>
        </>
      )}
    </SiteElementLayout>
  );
}
