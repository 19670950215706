import { TaggableUsersContext } from "./contexts/taggableUsersContext";
import Comments from "./comments";
import { useMemo } from "react";

export default function CommentsWrapper({
  taggableUsers,
  sectionId,
}: Readonly<{
  taggableUsers: any[];
  sectionId: number;
}>) {
  const value = useMemo(() => ({ taggableUsers }), [taggableUsers]);

  return (
    <TaggableUsersContext.Provider value={value}>
      <Comments sectionId={sectionId} />
    </TaggableUsersContext.Provider>
  );
}
