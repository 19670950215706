import { siteBcpStatusSelectList } from "../../../../constants/Site";
import { ArcheType, Area, Brand, Region } from "../../../../models/Brand";

export const siteFilterActions = {
  SET_BRANDS_DROPDOWN: "SET_BRANDS_DROPDOWN",
  ON_BRANDS_DROPDOWN_CHANGE: "ON_BRANDS_DROPDOWN_CHANGE",
  ON_SITETYPE_DROPDOWN_CHANGE: "ON_SITETYPE_DROPDOWN_CHANGE",
  ON_ARCHETYPE_DROPDOWN_CHANGE: "ON_ARCHETYPE_DROPDOWN_CHANGE",
  SET_REGIONS_DROPDOWN: "SET_REGIONS_DROPDOWN",
  SET_SITES_DROPDOWN: "SET_SITES_DROPDOWN",
  ON_REGIONS_DROPDOWN_CHANGE: "ON_REGIONS_DROPDOWN_CHANGE",
  ON_AREAS_DROPDOWN_CHANGE: "ON_AREAS_DROPDOWN_CHANGE",
  SET_COUNTRIES_DROPDOWN: "SET_COUNTRIES_DROPDOWN",
  ON_COUNTRY_DROPDOWN_CHANGE: "ON_COUNTRY_DROPDOWN_CHANGE",
  ON_BCPSTATUS_DROPDOWN_CHANGE: "ON_BCPSTATUS_DROPDOWN_CHANGE",
  ON_SITE_ASSIGNMENT_TYPE_CHANGE: "ON_SITE_ASSIGNMENT_TYPE_CHANGE",
  ON_SITES_DROPDOWN_CHANGE: "ON_SITES_DROPDOWN_CHANGE",
  ON_BRANDS_DROPDOWN_CLEAR: "ON_BRANDS_DROPDOWN_CLEAR",
  ON_REGIONS_DROPDOWN_CLEAR: "ON_REGIONS_DROPDOWN_CLEAR",
  ON_SITETYPE_DROPDOWN_CLEAR: "ON_SITETYPE_DROPDOWN_CLEAR",
  MAP_STATE_FROM_FILTERS: "MAP_STATE_FROM_FILTERS",
};

export const defaultSiteFilter = {
  brand: null,
  brands: [],
  siteType: null,
  archeType: [],
  region: null,
  areas: null,
  countries: [],
  sites: [],
  brandsDropdown: [],
  sitesDropdown: [],
  siteTypesDropdown: [],
  archeTypesDropdown: [],
  regionsDropdown: [],
  areasDropdown: [],
  countriesDropdown: [],
  bcpStatus: [],
  siteAssignmentType: "false",
};

export const siteFilterReducer = (state: any, action: any) => {
  switch (action.type) {
    case siteFilterActions.SET_BRANDS_DROPDOWN:
      return {
        ...state,
        brandsDropdown: action.brandsDropdown.map((brand: any) => ({
          id: brand.id,
          label: brand.businessBrandName,
          value: brand.id,
        })),
        brands: action.brandsDropdown,
      };
    case siteFilterActions.ON_BRANDS_DROPDOWN_CHANGE: {
      let brandsIndex = state.brands.findIndex(
        (item: any) => item.id === action.brand.id
      );
      return {
        ...state,
        brand: action.brand,
        siteTypesDropdown: state.brands[brandsIndex].siteTypes,
        regionsDropdown: state.brands[brandsIndex].regions,
        areasDropdown: [],
        siteType: "",
        region: null,
        areas: null,
      };
    }
    case siteFilterActions.ON_BRANDS_DROPDOWN_CLEAR:
      return {
        ...state,
        brand: null,
        siteTypesDropdown: [],
        siteType: null,
        archeTypesDropdown: [],
        archetype: [],
        regionsDropdown: [],
        region: null,
        areasDropdown: [],
        areas: null,
      };
    case siteFilterActions.SET_COUNTRIES_DROPDOWN:
      return { ...state, countriesDropdown: action.countriesDropdown };
    case siteFilterActions.ON_SITETYPE_DROPDOWN_CHANGE: {
      let siteTypeIndex = state.siteTypesDropdown.findIndex(
        (item: any) => item.id === action.siteTypeId.id
      );
      return {
        ...state,
        siteType: action.siteTypeId,
        archeTypesDropdown: state.siteTypesDropdown[siteTypeIndex].archeTypes,
      };
    }
    case siteFilterActions.ON_SITETYPE_DROPDOWN_CLEAR:
      return { ...state, siteType: null, archeTypesDropdown: [] };
    case siteFilterActions.ON_ARCHETYPE_DROPDOWN_CHANGE:
      return {
        ...state,
        archeType: action.archeType,
      };
    case siteFilterActions.ON_REGIONS_DROPDOWN_CHANGE: {
      let regionIndex = state.regionsDropdown.findIndex(
        (item: any) => item.regionId === action.regionId.id
      );
      return {
        ...state,
        region: action.regionId,
        areasDropdown: state.regionsDropdown[regionIndex].areas,
        areas: 0,
      };
    }
    case siteFilterActions.ON_REGIONS_DROPDOWN_CLEAR:
      return {
        ...state,
        region: null,
        areasDropdown: [],
        areas: null,
      };
    case siteFilterActions.ON_AREAS_DROPDOWN_CHANGE:
      return { ...state, areas: action.areaId };
    case siteFilterActions.ON_BCPSTATUS_DROPDOWN_CHANGE:
      return { ...state, bcpStatus: action.bcpStatus };
    case siteFilterActions.ON_COUNTRY_DROPDOWN_CHANGE:
      return { ...state, countries: action.countryIds };
    case siteFilterActions.ON_SITES_DROPDOWN_CHANGE:
      return { ...state, sites: action.siteIds };
    case siteFilterActions.ON_SITE_ASSIGNMENT_TYPE_CHANGE:
      return { ...state, siteAssignmentType: action.siteAssignmentType };
    case siteFilterActions.SET_SITES_DROPDOWN:
      return {
        ...state,
        sitesDropdown: action.sitesDropdown.map((site: any) => ({
          id: site.id,
          label: site.name,
          value: site.id,
        })),
      };
    case siteFilterActions.MAP_STATE_FROM_FILTERS: 
    {
      let mergedFilters = action.mergedFilters;
      let filteredCountries = [];
      let siteTypesFilterDropdown: any[] = [];
      let singleBrand = null;
      let siteTypeFilter = null;
      let archeTypesDropdown: any[] = [];
      let selectedArcheTypes: any[] = [];
      let regionsDropdown: any[] = [];
      let selectedRegions = null;
      let areasDropdown: any[] = [];
      let selectedAreas = null;
      let selectedBcpStatus: any[] = [];
      let selectedSites = [];

      if (mergedFilters.countryIds.length > 0) {
        let countries: any = state.countriesDropdown.filter((country: any) =>
          mergedFilters.countryIds.includes(country.id)
        );
        filteredCountries = countries;
      }
      if (mergedFilters.brandIds > 0) {
        let brands: Brand[] | null = state.brands.filter((brand: Brand) =>
          mergedFilters.brandIds.includes(brand.id)
        );

        let brand = brands
          ? {
              id: brands[0].id,
              label: brands[0].businessBrandName,
              value: brands[0].id,
            }
          : null;
        siteTypesFilterDropdown = brands ? brands[0].siteTypes : [];
        regionsDropdown = brands ? brands[0].regions ?? [] : [];

        if (mergedFilters.regionIds.length > 0) {
          let regions: Region[] | null = regionsDropdown.filter(
            (region: Region) =>
              mergedFilters.regionIds.includes(region.regionId)
          );

          selectedRegions =
            regions.length > 0
              ? {
                  id: regions[0].regionId,
                  label: regions[0].regionName,
                  value: regions[0].regionId,
                }
              : null;

          if (regions.length > 0) {
            areasDropdown = regions[0].areas;

            let selectedAreasFilter = regions[0].areas.filter((area: Area) =>
              mergedFilters.areaIds.includes(area.areaId)
            );

            selectedAreas =
              selectedAreasFilter.length > 0
                ? {
                    id: selectedAreasFilter[0].areaId,
                    label: selectedAreasFilter[0].areaName,
                    value: selectedAreasFilter[0].areaId,
                  }
                : 0;
          }
        }

        if (mergedFilters.siteTypeIds.length > 0) {
          let siteType = siteTypesFilterDropdown.find((siteType: any) =>
            mergedFilters.siteTypeIds.includes(siteType.id)
          );
          siteTypeFilter = siteType
            ? {
                id: siteType.id,
                label: siteType.siteTypeName,
                value: siteType.id,
              }
            : null;

          if (siteType) {
            archeTypesDropdown = siteType.archeTypes;
            selectedArcheTypes = siteType.archeTypes
              .filter((archeType: any) =>
                mergedFilters.archetypeIds.includes(archeType.id)
              )
              .map((archeType: ArcheType) => ({
                id: archeType.id,
                label: archeType.archeTypeName,
                value: archeType.id,
              }));
          }
        }

        singleBrand = brand;
      }
      if (mergedFilters.bcpStatusIds.length > 0) {
        selectedBcpStatus = siteBcpStatusSelectList
          .filter((bcpStatus: any) =>
            mergedFilters.bcpStatusIds.includes(bcpStatus.value)
          )
          .map((bcpStatus: any) => ({
            label: bcpStatus.label,
            value: bcpStatus.value,
            id: bcpStatus.value,
          }));
      }
      if (mergedFilters.siteIds.length > 0) {
        selectedSites = state.sitesDropdown
          .filter((site: any) => mergedFilters.siteIds.includes(site.id))
          .map((site: any) => ({
            id: site.id,
            label: site.name,
            value: site.id,
          }));
      }
      return {
        ...state,
        brand: singleBrand,
        siteTypesDropdown: siteTypesFilterDropdown,
        countries: filteredCountries,
        siteType: siteTypeFilter,
        archeTypesDropdown: archeTypesDropdown,
        archeType: selectedArcheTypes,
        regionsDropdown: regionsDropdown,
        region: selectedRegions,
        areasDropdown: areasDropdown,
        areas: selectedAreas,
        bcpStatus: selectedBcpStatus,
        sites: selectedSites,
        siteAssignmentType: mergedFilters.isSiteAssignedtoMeOnly.toString(),
      };
    }
    default:
      return state;
  }
};
