import { useEffect, useReducer } from "react";
import Welcome from "../Welcome";
import AddEditUserModal from "./modals/addEditUserModal";
import {
  defaultUsersState,
  usersActions,
  usersReducer,
} from "./reducers/users";
import { createOrUpdateUser, getUsers } from "../../services/UserManagement";
import { loadingIndicator } from "../../components/common/loading";
import { toast } from "../../components/common/toast";
import {
  McButton,
  McIcon,
  McTable,
  McTag,
} from "@maersk-global/mds-react-wrapper";
import Heading from "../../components/common/heading";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
} from "../../reducers/delete";
import DeleteModal from "../../components/common/deleteModal";
import { StaticIcon } from "../../components/common/icons";
import SiteMapping from "../Sites/modals/siteMapping";
import Info from "../../components/common/info";
import { UserInfo } from "../../constants/Info";
import Tooltip from "../../components/common/tooltip";

export default function Users() {
  const [state, dispatch] = useReducer(usersReducer, defaultUsersState);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    getUsers()
      .then((response) => {
        dispatch({ type: usersActions.SET_USERS, value: response });
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const onSaveUser = () => {
    getAllUsers();
  };

  const editUserClick = (user: any) => {
    dispatch({
      type: usersActions.SET_MODAL_OPEN,
      isModalOpen: true,
    });
    dispatch({
      type: usersActions.SET_EDITABLE_USER,
      user: user,
    });
  };

  const assignUserSites = (user: any) => {
    dispatch({
      type: usersActions.SET_USER_SITE_MAPPING,
      user: user,
    });
  };

  const closeMapUserToSitesModal = () => {
    dispatch({
      type: usersActions.SET_USER_SITE_MAPPING,
      user: null,
    });
    dispatch({
      type: usersActions.SET_USER_SITE_MAPPING_MODAL_CLOSE,
    });
  };

  const mapUserToSites = (siteIds: number[], userId: number) => {
    let user = state.users.find((user: any) => user.id === userId);

    let updatedUser = {
      Id: user.id,
      objectID: user.objectID,
      displayName: user.displayName,
      jobTitle: user.jobTitle,
      email: user.email,
      mobilePhone: user.mobilePhone,
      businessPhones: user.businessPhones,
      isActive: user.isActive,
      isPrimary: user.isPrimary,
      isSecondary: user.isSecondary,
      isShownInSites: user.isShownInSites,
      isIncidentResponseTeam: user.isIncidentResponseTeam,
      roleIDs: user.roles?.map((role: any) => role.id),
      siteIDs: siteIds,
    };

    createOrUpdateUser(updatedUser)
      .then((res) => {
        toast(
          "Success",
          `User 
          ${user.displayName} sites mapped successfully`,
          "success"
        );
      })
      .catch((error) => {
        toast("error", error.message, "error");
      })
      .finally(() => {
        getAllUsers();
        closeMapUserToSitesModal();
      });
  };

  const addNewUserClick = function () {
    dispatch({ type: usersActions.SET_MODAL_OPEN });
  };

  if (state.loading) {
    return loadingIndicator;
  }

  if (state.users.length === 0) {
    return (
      <Welcome
        heading={"Users"}
        subHeading={"There are no users added. Start by adding one."}
        buttonLabel={"Add new user"}
        onButtonClick={addNewUserClick}
      />
    );
  }

  return (
    <>
      <SiteMapping
        isOpen={state.isSiteMappingModalOpen}
        onClose={closeMapUserToSitesModal}
        onSave={mapUserToSites}
        entity={state.siteMappingUser}
        sites={state.siteMappingUser?.siteIDs || []}
        secondHeading="Sites Assigned to User"
      />
      <AddEditUserModal
        isOpen={state.isAddEditUserModalOpen}
        onSave={onSaveUser}
        editableUser={state.editableUser}
        onClose={() => {
          dispatch({ type: usersActions.RESET_EDITABLE_USER });
          dispatch({ type: usersActions.SET_MODAL_CLOSE });
        }}
      />
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSaveUser}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
        }}
      />
      <Heading
        heading={"Users"}
        subheading={
          <Info
            popoverContent={UserInfo.Page_Subheading}
            tooltipText="Configure users here."
          />
        }
      >
        <McButton
          id="add_user"
          label="Add new user"
          name="add new user"
          click={() => {
            dispatch({ type: usersActions.RESET_EDITABLE_USER });
            dispatch({
              type: usersActions.SET_MODAL_OPEN,
              isModalOpen: true,
            });
          }}
          variant="filled"
          fit="large"
          appearance="primary"
          icon="user-plus-circle"
        />
      </Heading>
      <McTable
        data={state.users}
        disableroundedcorners
        fit="small"
        columns={[
          { id: "displayName", label: "Name", sticky: "left" },
          { id: "jobTitle", label: "Job Title", sortDisabled: true },
          { id: "email", label: "Email" },
          { id: "mobilePhone", label: "Phone", sortDisabled: true },
          { id: "isActive", label: "Active" },
          { id: "brRole", label: "BR Role" },
          { id: "isPrimary", label: "Primary" },
          { id: "isSecondary", label: "Secondary" },
          { id: "isShownInSites", label: "Shown in sites" },
          {
            id: "isIncidentResponseTeam",
            label: "Incident Response team",
          },
          { id: "siteIDs", label: "Assigned Sites", sortDisabled: true },
          { id: "id", label: "", sortDisabled: true, sticky: "right" },
        ]}
      >
        {state.users.map((row: any) => (
          <>
            <div key={`${row.id}_isActive`} slot={`${row.id}_isActive`}>
              {row.isActive ? (
                <McIcon icon="check-circle-solid" color={"var(--mds_brand_appearance_success_default_background-color)"} />
              ) : (
                <McIcon icon="times-circle-solid" color={"var(--mds_brand_appearance_error_default_background-color)"} />
              )}
            </div>
            <div key={`${row.id}_isPrimary`} slot={`${row.id}_isPrimary`}>
              {row.isPrimary ? (
                <McIcon icon="check-circle-solid" color={"var(--mds_brand_appearance_success_default_background-color)"} />
              ) : (
                <McIcon icon="times-circle-solid" color={"var(--mds_brand_appearance_error_default_background-color)"} />
              )}
            </div>
            <div key={`${row.id}_isSecondary`} slot={`${row.id}_isSecondary`}>
              {row.isSecondary ? (
                <McIcon icon="check-circle-solid" color={"var(--mds_brand_appearance_success_default_background-color)"} />
              ) : (
                <McIcon icon="times-circle-solid" color={"var(--mds_brand_appearance_error_default_background-color)"} />
              )}
            </div>
            <div
              key={`${row.id}_isShownInSites`}
              slot={`${row.id}_isShownInSites`}
            >
              {row.isShownInSites ? (
                <McIcon icon="check-circle-solid" color={"var(--mds_brand_appearance_success_default_background-color)"} />
              ) : (
                <McIcon icon="times-circle-solid" color={"var(--mds_brand_appearance_error_default_background-color)"} />
              )}
            </div>
            <div
              key={`${row.id}_isIncidentResponseTeam`}
              slot={`${row.id}_isIncidentResponseTeam`}
            >
              {row.isIncidentResponseTeam ? (
                <McIcon icon="check-circle-solid" color={"var(--mds_brand_appearance_success_default_background-color)"} />
              ) : (
                <McIcon icon="times-circle-solid" color={"var(--mds_brand_appearance_error_default_background-color)"} />
              )}
            </div>
            <div key={`${row.id}_siteIDs`} slot={`${row.id}_siteIDs`}>
              <McTag
                fit="small"
                appearance="info"
                label={`${row.siteIDs.length}`}
              >
                <span slot="trailingicon">
                  {row.isActive && (
                    <Tooltip content="Click Here to Assign Site(s) to User.">
                    <StaticIcon
                      icon="plus"
                      slot="trigger"
                      color="var(--mds_brand_appearance_secondary_default_text-color)"
                      size="20"
                      onClick={() => {
                        assignUserSites(row);
                      }}
                    /></Tooltip>
                  )}
                </span>
              </McTag>
            </div>
            <div key={`${row.id}_roles`} slot={`${row.id}_roles`}>
              {row.roles.map((role: any) => role.brAppRole).join(", ")}
            </div>
            <div key={`${row.id}_id`} slot={`${row.id}_id`}>
              <McIcon
                icon="pencil"
                onClick={() => {
                  editUserClick(row);
                }}
              />
            </div>
          </>
        ))}
      </McTable>
    </>
  );
}
