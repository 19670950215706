import styled from "styled-components";
import Notifications from "./notifications";
import { useEffect, useRef } from "react";
import { McButton, McModal } from "@maersk-global/mds-react-wrapper";
import Box from "./box";

/**
 * `CommonModal` is a React functional component that renders a modal dialog 
 * using the `McModal` component from the `@maersk-global/mds-react-wrapper` package. 
 * It is designed to display dynamic content, including a heading, custom actions, and optionally, error notifications.
 *  The modal's appearance and behavior can be customized through various props.
 * Props:
 * - `errors`: string[] | undefined - An array of error messages to be displayed within the modal. If present, these errors are displayed using the `Notifications` component.
 * - `heading`: string - The text to be displayed as the modal's heading.
 * - `isOpen`: boolean - Controls the visibility of the modal. If `true`, the modal is open; otherwise, it is closed.
 * - `dimension`: "small" | "medium" | "large" | undefined - Specifies the size of the modal. Defaults to "medium" if not provided.
 * - `className`: string | undefined - An optional CSS class name to apply additional styling to the modal.
 * - `width`: string | undefined - An optional width for the modal, allowing for custom sizing beyond the `dimension` prop.
 * - `height`: string - The height of the modal, defaults to "auto".
 * - `onModalClose`: () => void - A callback function that is called when the modal is requested to be closed.
 * - `children`: React.ReactNode - The content to be displayed within the modal.
 * - `primaryAction`: () => void - A callback function for the primary action button within the modal.
 * - `primaryActionLabel`: string - The text to be displayed on the primary action button.
 * - `secondaryActionVariant`: "neutral" | "primary" | "secondary" | undefined - Specifies the variant of the secondary action button. Defaults to "neutral".
 * - `onSeceondaryAction`: () => void | undefined - An optional callback function for the secondary action button within the modal.
 * - `secondaryActionLabel`: string | undefined - The text to be displayed on the secondary action button, if it is used.
 * - `shouldShowSecondaryAction`: boolean - Determines whether the secondary action button is displayed. Defaults to `true`.

 * The component utilizes a `useEffect` hook to automatically scroll to the first error message (if any) when the modal is opened or when the errors change.

 * Usage Example:
 * ```tsx
 * <CommonModal
 *   errors={["Error message 1", "Error message 2"]}
 *   heading="Modal Heading"
 *   isOpen={true}
 *   onModalClose={() => console.log("Modal closed")}
 *   primaryAction={() => console.log("Primary action")}
 *   primaryActionLabel="Confirm"
 *   onSeceondaryAction={() => console.log("Secondary action")}
 *   secondaryActionLabel="Cancel"
 * >
 *   <p>Modal content goes here.</p>
 * </CommonModal>
 * ```

 * Note: This component is designed to be a versatile modal dialog with support for displaying errors, custom actions, and flexible content.
 */
export default function CommonModal({
  errors,
  heading,
  isOpen,
  dimension = "medium",
  className,
  width,
  height = "auto",
  onModalClose,
  children,
  primaryAction,
  primaryActionLabel,
  secondaryActionVariant = "neutral",
  onSeceondaryAction,
  secondaryActionLabel,
  shouldShowSecondaryAction = true,
  shouldShowTertiaryAction = false,
  tertiaryActionLabel,
  tertiaryAction,
}: any) {
  const validationMessageRef: any = useRef();

  useEffect(() => {
    if (errors && validationMessageRef.current) {
      validationMessageRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [errors]);

  return (
    <McModal
      heading={heading}
      open={isOpen}
      dimension={dimension}
      className={className}
      width={width || undefined}
      height={height}
      backdropcloseactiondisabled
      entercloseactiondisabled
      escapecloseactiondisabled
      hiddenclose
    >
      <ContentWrapper>
        {errors?.errorMessage?.length > 0 && (
          <div ref={validationMessageRef}>
            <Notifications
              description={errors.errorMessage}
              variant={"error"}
            />
          </div>
        )}
        {children}
      </ContentWrapper>
      <McButton
        focusstartanchor
        slot="primaryAction"
        appearance="primary"
        click={primaryAction}
      >
        {primaryActionLabel}
      </McButton>
      {shouldShowTertiaryAction && (
        <McButton
          slot="secondaryAction"
          appearance="neutral"
          variant="filled"
          click={tertiaryAction}
        >
          {tertiaryActionLabel}
        </McButton>
      )}
      {shouldShowSecondaryAction && (
        <McButton
          data-testid="secondary"
          focusendanchor
          slot="secondaryAction"
          appearance="neutral"
          variant={secondaryActionVariant}
          click={onSeceondaryAction}
        >
          {secondaryActionLabel}
        </McButton>
      )}
    </McModal>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ExpandableContentWrapper = styled.div`
  padding: 20px;
  border: 1px solid var(--mds_brand_appearance_neutral_weak_border-color);
`;

export const ExpandableContentDeleteButtonWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: end;
`;

export const DeleteButtonWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: end;
  margin-top: 33px;
`;

export const AddNewContentText = styled.span`
  cursor: pointer;
  color: var(--mds_brand_appearance_secondary_default_text-color);
  display: flex;
`;
