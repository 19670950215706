import { useEffect, useState } from "react";
import CommonModal from "./commonModal";
import Notifications from "./notifications";
import { deleteBusinessEntity } from "../../services/Brands";
import { toast, toastError } from "./toast";

/**
 * DeleteModal Component
 *
 * A modal component designed for confirming the deletion of a business entity. It utilizes a common modal
 * layout (`CommonModal`) and integrates with a deletion service (`deleteBusinessEntity`) to perform the
 * actual deletion operation. The component also supports notifications via the `toast` function to provide
 * feedback to the user.
 *
 * Props:
 * - `deleteEntity`: Object containing details about the entity to be deleted. Should include `keyName`, `key`, `id`, `path`, and `info`.
 * - `isOpen`: Boolean indicating whether the modal is currently open.
 * - `onClose`: Function to be called when the modal is requested to be closed, without performing the delete operation.
 * - `onSave`: Function to be called when the delete operation is confirmed.
 *
 * State:
 * - `keyName`: String representing a human-readable name or identifier for the entity to be deleted.
 * - `key`: String representing a unique key or identifier associated with the entity to be deleted.
 * - `id`: Number representing the unique ID of the entity to be deleted.
 * - `path`: String representing the API path or endpoint associated with the entity to be deleted.
 * - `info`: String providing additional information or context about the deletion operation.
 *
 * The component listens for changes to the `isOpen` prop to reset its internal state based on the `deleteEntity`
 * prop, ensuring that it reflects the current entity to be deleted whenever the modal is opened.
 *
 * Usage:
 * Intended to be used within a UI that requires user confirmation for deleting entities, such as items in a list
 * or entries in a database. The `onClose` and `onSave` callbacks allow for custom handling of the modal's closure
 * and the execution of the deletion operation, respectively.
 */
export default function DeleteModal({
  deleteEntity,
  isOpen,
  onClose,
  onSave,
}: Readonly<{
  deleteEntity: any;
  isOpen: boolean;
  onClose: any;
  onSave: any;
}>) {
  const [keyName, setKeyName] = useState("");
  const [key, setKey] = useState("");
  const [id, setId] = useState(0);
  const [path, setPath] = useState("");
  const [info, setInfo] = useState("");

  useEffect(() => {
    let { keyName, key, id, path, info } = deleteEntity;

    setKeyName(keyName);
    setKey(key);
    setId(id);
    setPath(path);
    setInfo(info);
  }, [isOpen]);

  const onModalClose = () => {
    onClose();
  };

  const onDeleteEntity = (
    id: number,
    key: string,
    keyName: string,
    path: string
  ) => {
    deleteBusinessEntity(id, path)
      .then((response) => {
        toast("Success", `${key} - ${keyName} deleted successfully`, "success");
        onSave();
        onModalClose();
      })
      .catch((error) => {
        toastError(error.message);
        onModalClose();
      });
  };

  return (
    <CommonModal
      primaryActionLabel="Delete"
      primaryAction={() => onDeleteEntity(id, key, keyName, path)}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={isOpen}
      onModalClose={() => onModalClose()}
      heading="Delete"
      dimension={info !== "" ? "medium" : "small"}
      className={info !== "" ? "" : "delete-modal"}
      width="600px"
      height="500px"
    >
      {info && <Notifications description={[info]} variant={"warning"} />}
      <span>
        Are you sure you want to delete this {key} - <b>{keyName}</b>?
      </span>
    </CommonModal>
  );
}
