export const processesActions = {
  SET_LOADING: "SET_LOADING",
  SET_MODAL_OPEN: "SET_MODAL_OPEN",
  SET_MODAL_CLOSE: "SET_MODAL_CLOSE",
  SET_PROCESS_EDIT_ENABLED: "SET_PROCESS_EDIT_ENABLED",
  SET_PROCESS_EDIT_DISABLED: "SET_PROCESS_EDIT_DISABLED",
  SET_PROCESS_NAME: "SET_PROCESS_NAME",
  IMPACTS_DROPDOWN_CHANGE: "IMPACTS_DROPDOWN_CHANGE",
  CRITICALITY_DROPDOWN_CHANGE: "CRITICALITY_DROPDOWN_CHANGE",
  SET_PROCESSES: "SET_PROCESSES",
  SET_EDITABLE_PROCESS: "SET_EDITABLE_PROCESS",
  SET_DELETE_MODAL_OPEN: "SET_DELETE_MODAL_OPEN",
  SET_DELETE_ENTITY: "SET_DELETE_ENTITY",
};

const defaultDeleteEntityState = {
  id: 0,
  key: "",
  keyName: "",
  path: "",
  info: "",
};

export const defaultProcesses = {
  loading: true,
  isAddProcessImpactModalOpen: false,
  tableColumns: [],
  canAddNewProcess: true,
  impactsDropdown: [],
  criticalityDropdown: [],
  processes: [],
  isProcess: true,
  editableProcess: null,
  deleteEntity: defaultDeleteEntityState,
};

export const processImpactsReducer = (state: any, action: any) => {
  switch (action.type) {
    case processesActions.SET_LOADING:
      return { ...state, loading: action.loading };
    case processesActions.SET_MODAL_OPEN:
      return {
        ...state,
        isAddProcessImpactModalOpen: true,
      };
    case processesActions.SET_PROCESSES: {
      let impacts = action.value.impactConfiguration;
      let canAddNewProcess = setCanAddNewProcess(impacts);
      if (!canAddNewProcess) {
        return {
          ...state,
          canAddNewProcess: canAddNewProcess,
        };
      }
      let setProcesses = action.value.processImpactMappings;
      let unit = impacts.timeIntervalUnit;
      let sortedTimeInterval = [...impacts.timeInterval].sort((a, b) =>
        Number(a.name) > Number(b.name) ? 1 : -1
      );
      let tableColumns = sortedTimeInterval.map((timeInterval: any) => {
        let header = `${timeInterval.name}${unit}`;
        return {
          accessorKey: timeInterval.name,
          header: header,
          id: timeInterval.name,
          enableSorting: false,
          meta: {
            type: "custom",
          },
          size: 80,
          minSize: 80,
        };
      });
      let orderedTimeInterval = sortedTimeInterval.map(
        (timeInterval: any, index: number) => {
          return {
            name: timeInterval.name,
            id: timeInterval.id,
            order: index,
          };
        }
      );
      impacts.timeInterval = orderedTimeInterval;
      let isProcess = !action.value.doesSubProcessExist;
      return {
        ...state,
        processes: setProcesses,
        tableColumns: tableColumns,
        isProcess: isProcess,
        impacts: impacts,
      };
    }
    case processesActions.SET_MODAL_CLOSE:
      return {
        ...state,
        editableProcess: null,
        isAddProcessImpactModalOpen: false,
      };
    case processesActions.SET_PROCESS_NAME: 
    {
      let processes = state.processes;
      let index = action.index;
      processes[index].processName = action.value;
      return {
        ...state,
        processes: processes,
      };
    }
    case processesActions.SET_EDITABLE_PROCESS:
      return {
        ...state,
        editableProcess: action.value,
      };
    case processesActions.SET_DELETE_MODAL_OPEN:
      return {
        ...state,
        isDeleteModalOpen: action.isDeleteModalOpen,
      };
    case processesActions.SET_DELETE_ENTITY:
      return {
        ...state,
        deleteEntity: {
          id: action.deleteEntity.id,
          key: action.deleteEntity.key,
          keyName: action.deleteEntity.keyName,
          path: action.deleteEntity.path,
          info: action.deleteEntity.info,
        },
      };
  }
  return state;
};

const setCanAddNewProcess = (impacts: any) => {
  return (
    impacts?.impact?.length > 0 &&
    impacts?.criticality?.length > 0 &&
    impacts?.timeInterval?.length > 0 &&
    impacts?.timeIntervalUnit &&
    impacts?.criticalityRTO &&
    impacts?.depedencyRTO
  );
};
