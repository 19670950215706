import { useEffect, useReducer, useState } from "react";
import CommonModal from "../../../../components/common/commonModal";
import { ConfigurationMapping } from "../../../../models/AlternativeLocation";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import { configuration } from "../../../../constants/Site";
import { McInput } from "@maersk-global/mds-react-wrapper";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../../reducers/error";
import useSite from "../../../../hooks/useSite";
import { toastSuccess } from "../../../../components/common/toast";
import {
  EmergencyContactsKeys,
  SaveEmergencyContactsModel,
} from "../../../../models/EmergencyContacts";
import { saveEmergencyContact } from "../../../../services/Site/EmergencyContact";
import { validatePhoneNumber } from "../../../../utils/validation-utils";
import { trimStringsBeforeSave } from "../../../../utils/utils";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";

const defaultEmergencyContacts: SaveEmergencyContactsModel = {
  siteId: 0,
  versionId: 0,
  id: 0,
  serviceName: "",
  phoneNumber: "",
  isConfiguredArchetypeLevel: false,
};

export default function AddEditEmergencyContact({
  isOpen,
  editableEmergencyContact,
  configurations,
  keys,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableEmergencyContact: any;
  configurations: ConfigurationMapping | undefined;
  keys: EmergencyContactsKeys;
  onClose: () => void;
  onSave: () => void;
}>) {
  const { site } = useSite();
  const [editableEmergencyContacts, setEditableEmergencyContacts] =
    useState<SaveEmergencyContactsModel>(defaultEmergencyContacts);
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    if (editableEmergencyContact != null) {
      setEditableEmergencyContacts(editableEmergencyContact);
    } else {
      setEditableEmergencyContacts({
        ...defaultEmergencyContacts,
        siteId: site.id,
        versionId: site.versionId,
      });
    }
  }, [isOpen]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEditableEmergencyContacts({
      ...editableEmergencyContacts,
      [name]: value,
    });
  };

  const onModalClose = () => {
    onClose();
  };

  const isMandatory = (key: string): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  const validateInternalContacts = () => {
    const errors: string[] = [];
    let { serviceName, phoneNumber } = editableEmergencyContacts;

    if (isMandatory(keys.serviceName) && !serviceName) {
      errors.push("Service is required");
    }
    if (isMandatory(keys.phoneNumber) && !phoneNumber) {
      errors.push("Phone number is required");
    }
    if (phoneNumber && !validatePhoneNumber(phoneNumber.trim())) {
      errors.push("phoneNumber is invalid");
    }
    return errors;
  };

  const addOrUpdateEmergencyContacts = () => {
    const errors = validateInternalContacts();
    if (errors.length > 0) {
      dispatchErrors({ type: errorActions.ADD_ERROR, errorMessage: errors });
      return;
    }

    saveEmergencyContact(trimStringsBeforeSave(editableEmergencyContacts))
      .then((response) => {
        toastSuccess("Emergency Contact saved successfully");
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  return (
    <div>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={addOrUpdateEmergencyContacts}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={onModalClose}
        isOpen={isOpen}
        onModalClose={onModalClose}
        heading={"Add/Edit Emergency Contact"}
        dimension="medium"
        errors={errors}
      >
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.serviceName}
        >
          <McInput
            label=""
            fit="small"
            value={editableEmergencyContacts.serviceName}
            input={(e: any) => handleInputChange(e)}
            name="serviceName"
            disabled={editableEmergencyContacts.isConfiguredArchetypeLevel}
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Description"
              isMandatory={isMandatory(keys.serviceName)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.phoneNumber}
        >
          <McInput
            label="Phone"
            fit="small"
            value={editableEmergencyContacts.phoneNumber}
            input={(e: any) => handleInputChange(e)}
            name="phoneNumber"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Phone"
              isMandatory={isMandatory(keys.phoneNumber)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
      </CommonModal>
    </div>
  );
}
