import { useState } from "react";
import styled from "styled-components";

export default function SettingsCategories({
  masterCategories,
  onSelectCategory,
}: Readonly<{
  masterCategories: any;
  onSelectCategory: (code: string) => void;
}>) {
  const [categories, setCategories] = useState(masterCategories);

  const setSelectedCategory = (code: string) => {
    let updatedCategories = categories.map((category: any) => {
      if (category.code === code) {
        return { ...category, isSelected: true };
      } else {
        return { ...category, isSelected: false };
      }
    });
    setCategories(updatedCategories);

    onSelectCategory(code);
  };

  return (
    <div style={{ paddingBottom: "20px" }}>
      {categories.map((category: any) => (
        <SettingsCategory
          key={category.code}
          isSelected={category.isSelected}
          onClick={() => setSelectedCategory(category.code)}
        >
          <span> {category.name} </span>
        </SettingsCategory>
      ))}
    </div>
  );
}

const SettingsCategory = styled.div<{ isSelected: boolean }>`
  margin-top: 10px;
  height: 40px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: ${(props) =>
    props.isSelected
      ? "var(--mds_brand_appearance_primary_default_background-color)"
      : "var(--mds_brand_appearance_neutral_default_background-color)"};
  align-content: center;
  padding-left: 20px;

  span {
    color: ${(props) =>
      props.isSelected
        ? "var(--mds_brand_appearance_primary_default_on-background-color)"
        : "var(--mds_brand_appearance_neutral_default_on-background-color)"};
  }
`;
