import React from "react";
import Box from "./box";
import { McPopover } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";

const Info = ({
  popoverContent,
  tooltipText,
  children,
}: {
  popoverContent: React.ReactNode;
  tooltipText: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <span slot="label">
      <Box display="flex" alignItems="center" gridGap={1}>
        <span>{tooltipText}</span>
        <McPopover
          trigger="hover"
          open={false}
          opendelay={50}
          modalmode="none"
          arrow
          position="bottom-left"
          fit="large"
          preventcloseonblur={false}
          maxwidth="auto"
        >
          <StaticIcon
            fit="small"
            hiddenlabel
            variant="filled"
            icon="info-circle"
            appearance="neutral"
            slot="trigger"
          />
          <div
            style={{
              padding: "15px",
              textWrap: "wrap",
              display: "flex",
              gap: "8px",
            }}
          >
            {popoverContent}
          </div>
        </McPopover>
      </Box>
    </span>
  );
};

export default Info;
