import { useContext, useState } from "react";
import Action from "../common/action";
import { McButton } from "@maersk-global/mds-react-wrapper";
import CommentReply from "./commentReply";
import { UserContext } from "../layout/layout";
import {
  addReply,
  deleteComment,
  updateComment,
} from "../../services/Comments";
import { toastError } from "../common/toast";
import {
  extractMentions,
  formatDate,
  replacePlaceholder,
} from "../../utils/utils";
import TagInput from "./tagInput";

export default function Comment({
  id,
  sectionId,
  updatedBy,
  date,
  commentText,
  replies,
  canReply = true,
  parentId = 0,
  isEdited,
  isDeleted,
}: Readonly<{
  id: number;
  sectionId: number;
  updatedBy: string;
  date: string;
  commentText: string;
  isEdited: boolean;
  isDeleted: boolean;
  parentId?: number;
  replies?: ReadonlyArray<{
    id: number;
    userName: string;
    updatedAt: string;
    replyBody: string;
    isEdited: boolean;
    isDeleted: boolean;
  }>;
  canReply?: boolean;
}>): JSX.Element {
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCommentDeleted, setIsCommentDeleted] = useState(isDeleted);
  const [isCommentEdited, setIsCommentEdited] = useState(isEdited);
  const [editedDateTime, setEditedDateTime] = useState(date);

  const [editedComments, setEditedComments] = useState(commentText);
  const [commentReplies, setCommentReplies] = useState(replies);
  const { email } = useContext(UserContext);

  const onCreateReply = (comments: string, id: number) => {
    let replyId = commentReplies?.length ?? 0;
    const newReply = {
      id: replyId,
      userName: email,
      updatedAt: new Date().toISOString(),
      replyBody: comments,
      isEdited: false,
      isDeleted: false,
    };
    setCommentReplies([...(commentReplies ?? []), newReply]);
    setIsReplying(false);

    let taggedUsers = extractMentions(comments);

    addReply(sectionId, id, comments, email, taggedUsers)
      .then((response) => handleAddReplySuccess(response, replyId))
      .catch(handleAddReplyError);
  };

  const handleAddReplySuccess = (response: number, replyId: number) => {
    setCommentReplies((prevReplies = []) =>
      prevReplies.map((reply) =>
        reply.id === replyId ? { ...reply, id: response } : reply
      )
    );
  };

  const handleAddReplyError = (error: any) => {
    toastError("Failed to add reply");
  };

  const onEditClick = () => {
    setIsEditing(true);
  };

  const onDeleteClick = () => {
    setEditedComments("");
    setIsCommentDeleted(true);

    deleteComment(id, parentId !== 0)
      .then((response) => {})
      .catch((error) => {
        setIsCommentDeleted(false);
        toastError("Failed to delete comment");
      });
  };

  const onEditSubmit = () => {
    setIsEditing(false);
    setIsCommentEdited(true);
    setEditedDateTime(new Date().toISOString());

    let taggedUsers = extractMentions(editedComments);

    updateComment(
      id,
      editedComments,
      email,
      parentId !== 0,
      taggedUsers,
      sectionId
    )
      .then((response) => {})
      .catch((error) => {
        toastError("Failed to edit comment");
      });
  };

  const onEditCancel = () => {
    setEditedComments(commentText);
    setIsEditing(false);
  };

  const getCommentStatus = (
    isDeleted: boolean,
    isEdited: boolean,
    date: string
  ) => {
    let formattedDate = formatDate(date);
    if (isDeleted) {
      return <div> Deleted on {formattedDate}</div>;
    } else if (isEdited) {
      return <div> Edited on {formattedDate}</div>;
    } else {
      return formattedDate;
    }
  };

  return (
    <div className="comment-container">
      <div className="comment-text">
        <div className="comment-box">
          <div className="comment-updated-by">{updatedBy}</div>{" "}
          <div className="comment-status">
            {getCommentStatus(
              isCommentDeleted,
              isCommentEdited,
              editedDateTime
            )}
          </div>
          <div className="flex-grow" />
          <div className="comment-actions">
            {email === updatedBy && !isCommentDeleted && (
              <Action
                items={[
                  {
                    label: "Edit",
                    onClick: () => {
                      onEditClick();
                    },
                  },
                  {
                    label: "Delete",
                    onClick: () => {
                      onDeleteClick();
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
        {isEditing ? (
          <div className="editing-container">
            <div className="flex-grow">
              <TagInput
                value={editedComments}
                onTextChange={setEditedComments}
              />
            </div>
            <div className="align-end">
              <McButton
                icon="check"
                appearance="neutral"
                variant="outlined"
                fit="small"
                click={onEditSubmit}
              />
            </div>
            <div className="align-end">
              <McButton
                icon="times"
                appearance="neutral"
                variant="outlined"
                fit="small"
                click={onEditCancel}
              />
            </div>
          </div>
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: replacePlaceholder(editedComments),
            }}
          />
        )}
        <div>
          {!isEditing && !isCommentDeleted && canReply && (
            <button
              className="reply-button"
              onClick={(event: any) => {
                event.stopPropagation();
                setIsReplying(!isReplying);
              }}
              tabIndex={0}
            >
              REPLY
            </button>
          )}
        </div>
      </div>
      <div className="comment-replies">
        {commentReplies?.map((reply) => (
          <Comment
            key={reply.id}
            id={reply.id}
            updatedBy={reply.userName}
            date={reply.updatedAt}
            commentText={reply.replyBody}
            canReply={false}
            isEdited={reply.isEdited}
            isDeleted={reply.isDeleted ?? false}
            parentId={id}
            sectionId={sectionId}
          />
        ))}
        {isReplying && <CommentReply onSend={onCreateReply} id={id} />}
      </div>
    </div>
  );
}
