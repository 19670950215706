import React from "react";
import { CardKeyValueDisplay } from "./cardKeyValueDisplay";
import { McButton } from "@maersk-global/mds-react-wrapper";
import { Box } from "@maersk-global/community-react";
import { DeleteIcon, StaticIcon } from "./icons";
import { FlexGrowBox } from "./common.styles";
import HorizontalLine from "./horizontalLine";

interface CardsProps {
  items: any;
  id: number;
  title: string;
  allowEdit: boolean;
  allowDelete: boolean;
  displayWarning?: boolean;
  onEdit?: () => void;
  onDelete?: (id: number, path: string) => void;
}

export const Cards: React.FC<CardsProps> = ({
  items,
  id,
  title,
  allowEdit,
  allowDelete,
  displayWarning = false,
  onEdit,
  onDelete,
}) => {
  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(id, title);
    }
  };

  const handleEditClick = () => {
    if (onEdit) {
      onEdit();
    }
  };

  return (
    <>
      <Box display="flex">
        <Box display="flex">
          <b>{title}</b>
          {displayWarning && <StaticIcon icon="exclamation-circle" size="20" />}
        </Box>
        <FlexGrowBox />
        <Box display="flex" style={{ gap: "8px" }}>
          {allowEdit && (
            <McButton
              icon="pencil"
              appearance="neutral"
              variant="outlined"
              fit="small"
              click={handleEditClick}
              aria-label="edit-button"
            />
          )}
          {allowDelete && (
            <McButton
              appearance="neutral"
              variant="outlined"
              fit="small"
              click={handleDeleteClick}
              aria-label="delete-button"
            >
              <span slot="icon">
                <DeleteIcon />
              </span>
            </McButton>
          )}
        </Box>
      </Box>
      <HorizontalLine />
      {items.map((item: any, index: any) =>
        item === null ? (
          <>
            {" "}
            <br />{" "}
          </>
        ) : (
          <>
            {" "}
            <CardKeyValueDisplay
              key={item.label}
              label={item.label}
              value={item.value}
            />{" "}
          </>
        )
      )}
    </>
  );
};
