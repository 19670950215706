import { useEffect, useReducer, useState } from "react";
import CommonModal from "../../../../components/common/commonModal";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../../reducers/error";
import {
  McInput,
  McOption,
  McSelect,
  McTextAndIcon,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import { TextAndIconBody } from "../../../Customers/modals/addEditCustomer";
import { configuration } from "../../../../constants/Site";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import {
  ConfigurationMapping,
  SaveAlternativeLocationModel,
} from "../../../../models/AlternativeLocation";
import useSite from "../../../../hooks/useSite";
import { saveAlternativeLocation } from "../../../../services/Site/AlternativeLocation";
import { toastSuccess } from "../../../../components/common/toast";
import { SelectBoolean } from "../../../../components/common/selectBoolean";
import {
  timeIntervalOption,
  timeIntervalOptions,
} from "../../../../constants/SelectItems";
import { alternativeLocationKeys } from "../AlternativeLocations";
import Box from "../../../../components/common/box";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";

const expandableSections = [
  {
    label: "Site Details",
    isExpanded: false,
  },
  {
    label: "Warehouse Details",
    isExpanded: false,
  },
  {
    label: "Others",
    isExpanded: false,
  },
];

const warehouseTypeOptions = [
  { label: "Air", value: "1" },
  { label: "Cold Storage", value: "2" },
  { label: "Consolidation", value: "3" },
  { label: "Deconsolidation", value: "4" },
  { label: "Depot", value: "5" },
  { label: "Fulfilment", value: "6" },
  { label: "Maersk e-delivery", value: "7" },
  { label: "Maersk e-fulfilment", value: "8" },
  { label: "SCM Consolidation", value: "9" },
  { label: "Transportation", value: "10" },
  { label: "Transportation - delivery stations", value: "11" },
  { label: "Transportation - sortation hubs", value: "12" },
];

const bcpStrategyOptions = [
  { label: "WLD", value: "1" },
  { label: "CT", value: "2" },
  { label: "Split operations", value: "3" },
];

const defaultAlternativeLocation: SaveAlternativeLocationModel = {
  id: 0,
  siteId: 0,
  versionId: 0,
  alternativeSiteId: 0,
  siteOperator: null,
  wareHouseTypeId: null,
  fileDetails: [],
  distanceToMajorPort: null,
  distanceFromCurrentWarehouse: null,
  wmsUsed: null,
  wmsType: null,
  businessContinuityTime: null,
  fteCountForSupport: null,
  bcpStartegyId: null,
  shiftDetails: null,
  itAppsAccessRequirements: null,
  constraintsImpactingContinuity: null,
  constraintsImpactingContinuityRemarks: null,
  activationPreparation: null,
  activationPreparationRemarks: "",
  otherRemarks: "",
  businessContinuityTimeUnitId: null,
  siteName: "",
  siteOwner: "",
  address: "",
  siteCode: "",
};

export default function AddEditAlternativeLocation({
  isOpen,
  editableAlternativeLocation,
  configurations,
  sites,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableAlternativeLocation: any;
  configurations: ConfigurationMapping | undefined;
  sites: any;
  onClose: () => void;
  onSave: () => void;
}>): JSX.Element {
  const { site } = useSite();
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [expandableSection, setExpandableSection] =
    useState(expandableSections);

  const [editableAlternateLocation, setEditableAlternateLocation] =
    useState<SaveAlternativeLocationModel>({
      ...defaultAlternativeLocation,
      siteId: site.id,
      versionId: site.versionId,
    });

  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });

    if (editableAlternativeLocation != null) {
      setEditableAlternateLocation(editableAlternativeLocation);
    } else {
      setEditableAlternateLocation({
        ...defaultAlternativeLocation,
        siteId: site.id,
        versionId: site.versionId,
      });
    }

    handleSectionExpansion(0);
  }, [isOpen]);

  const handleSectionExpansion = (index: number) => {
    const newExpandableSections = [...expandableSection];

    newExpandableSections.forEach((section) => {
      section.isExpanded = false;
    });

    newExpandableSections[index].isExpanded =
      !newExpandableSections[index].isExpanded;
    setExpandableSection(newExpandableSections);
  };

  const handleSiteChange = (selectedSiteId: string) => {
    const selectedSite = sites.find(
      (item: any) => item.siteId === parseInt(selectedSiteId)
    );

    setEditableAlternateLocation({
      ...editableAlternateLocation,
      alternativeSiteId: selectedSite.siteId,
      siteCode: selectedSite.siteCode,
      siteName: selectedSite.siteName,
      siteOwner: selectedSite.siteOwner ?? "",
      address: `${selectedSite.address}`,
      fileDetails: selectedSite.fileDetails,
    });
  };

  const handleInputChange = (name: string, value: any) => {
    setEditableAlternateLocation({
      ...editableAlternateLocation,
      [name]: value,
    });
  };

  const handleClearButtonClick = (name: string) => {
    setEditableAlternateLocation({
      ...editableAlternateLocation,
      [name]: null,
    });
  };

  const addOrUpdateAlternativeLocation = () => {
    const siteErrors = validateSiteDetails();
    const warehouseErrors = validateWarehouseDetails();
    const otherErrors = validateOthers();

    const errors = [...siteErrors, ...warehouseErrors, ...otherErrors];

    if (errors.length > 0) {
      dispatchErrors({ type: errorActions.ADD_ERROR, errorMessage: errors });
      return;
    }

    saveAlternativeLocation(editableAlternateLocation)
      .then((response) => {
        toastSuccess("Alternative Location saved successfully");
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const onModalClose = () => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    onClose();
  };

  const isMandatory = (key: string): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  const validateSiteDetails = () => {
    const errors: string[] = [];

    let { siteOperator } = editableAlternateLocation;

    if (!siteOperator) {
      errors.push("Site Operator is required");
    }

    return errors;
  };

  const validateWarehouseDetails = () => {
    const errors: string[] = [];
    let {
      wareHouseTypeId,
      distanceToMajorPort,
      distanceFromCurrentWarehouse,
      wmsUsed,
      wmsType,
    } = editableAlternateLocation;

    if (
      isMandatory(alternativeLocationKeys.warehouseType) &&
      !wareHouseTypeId
    ) {
      errors.push("Warehouse Type is required");
    }
    if (
      isMandatory(alternativeLocationKeys.distanceToMajorPort) &&
      !distanceToMajorPort
    ) {
      errors.push("Distance to major port is required");
    }

    if (
      distanceToMajorPort &&
      (distanceToMajorPort < 0 || distanceToMajorPort > 10000)
    ) {
      errors.push("Distance to major port should be between 0 and 10000");
    }

    if (
      isMandatory(alternativeLocationKeys.distanceFromCurrentWarehouse) &&
      !distanceFromCurrentWarehouse
    ) {
      errors.push("Distance from current warehouse is required");
    }

    if (
      distanceFromCurrentWarehouse &&
      (distanceFromCurrentWarehouse < 0 || distanceFromCurrentWarehouse > 10000)
    ) {
      errors.push(
        "Distance from current warehouse should be between 0 and 10000"
      );
    }

    if (isMandatory(alternativeLocationKeys.wmsUsed) && wmsUsed === null) {
      errors.push("WMS used is required");
    }
    if (wmsUsed !== null && wmsUsed && !wmsType)
      errors.push("WMS type is required");

    return errors;
  };

  const validateOthers = () => {
    let {
      businessContinuityTime,
      businessContinuityTimeUnitId,
      fteCountForSupport,
      bcpStartegyId,
      shiftDetails,
      itAppsAccessRequirements,
      constraintsImpactingContinuity,
      constraintsImpactingContinuityRemarks,
      activationPreparation,
      activationPreparationRemarks,
      otherRemarks,
    } = editableAlternateLocation;

    const errors: string[] = [];

    if (
      isMandatory(alternativeLocationKeys.businessContinuityTime) &&
      !businessContinuityTime
    ) {
      errors.push("Business Continuity time is required");
    }

    if (businessContinuityTime && !businessContinuityTimeUnitId) {
      errors.push("Time interval unit is required.");
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 1500) &&
      businessContinuityTimeUnitId === timeIntervalOption.Hours
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 1500 when time unit is hours."
      );
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 60) &&
      businessContinuityTimeUnitId === timeIntervalOption.Days
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 60 when time unit is days."
      );
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 10) &&
      businessContinuityTimeUnitId === timeIntervalOption.Weeks
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 10 when time unit is weeks."
      );
    }

    if (
      isMandatory(alternativeLocationKeys.fteCountForSupport) &&
      !fteCountForSupport
    ) {
      errors.push("FTE Count for support is required");
    }

    if (
      fteCountForSupport &&
      (fteCountForSupport < 0 || fteCountForSupport > 1000)
    ) {
      errors.push("FTE Count for support should be between 0 and 1000");
    }

    if (isMandatory(alternativeLocationKeys.bcpStrategy) && !bcpStartegyId) {
      errors.push("BCP Strategy is required");
    }

    if (isMandatory(alternativeLocationKeys.shiftDetails) && !shiftDetails) {
      errors.push("Shift details is required");
    }

    if (
      isMandatory(alternativeLocationKeys.itAppsAccessRequirements) &&
      !itAppsAccessRequirements
    ) {
      errors.push("IT Apps/Access requirements is required");
    }

    if (
      isMandatory(alternativeLocationKeys.constraintsImpactingContinuity) &&
      constraintsImpactingContinuity === null
    ) {
      errors.push("Constraints impacting continuity is required");
    }

    if (
      constraintsImpactingContinuity &&
      !constraintsImpactingContinuityRemarks
    )
      errors.push("Constraints Impacting Continuity Remarks is required");

    if (
      isMandatory(alternativeLocationKeys.activationPreparation) &&
      activationPreparation === null
    ) {
      errors.push("Activation Preparation is required");
    }

    if (activationPreparation && !activationPreparationRemarks) {
      errors.push("Activation Preparation Remarks is required");
    }

    if (isMandatory(alternativeLocationKeys.otherRemarks) && !otherRemarks) {
      errors.push("Other Remarks is required");
    }

    return errors;
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={addOrUpdateAlternativeLocation}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={onModalClose}
      isOpen={isOpen}
      closed={onModalClose}
      heading={"Add/edit Alternative Location"}
      dimension="medium"
      errors={errors}
    >
      <McTextAndIcon
        label={expandableSection[0].label}
        fit="large"
        icon={expandableSection[0].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(0);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[0].isExpanded}>
        <McSelect
          label=""
          fit="small"
          value={editableAlternateLocation.alternativeSiteId.toString()}
          optionselected={(e: CustomEvent) => handleSiteChange(e.detail.value)}
          name="siteName"
        >
          {sites.map((item: any) => {
            return (
              <McOption key={item.siteId} value={item.siteId.toString()}>
                {item.siteName} ({item.siteCode})
              </McOption>
            );
          })}
          <SiteMandatoryLabel
            slot="label"
            label="Site Name"
            isMandatory={true}
          />
        </McSelect>
        <McInput
          label="Site Code"
          fit="small"
          value={editableAlternateLocation.siteCode.toString()}
          name="alternativeSiteId"
          input={(e: any) => handleInputChange(e.target.name, e.target.value)}
          disabled
        />
        <McInput
          label="Site Owner"
          fit="small"
          value={editableAlternateLocation.siteOwner}
          input={(e: any) => handleInputChange(e.target.name, e.target.value)}
          name="siteOwner"
          disabled
        />
        <McTextarea
          label="Address"
          fit="small"
          value={editableAlternateLocation.address}
          input={(e: any) => handleInputChange(e.target.name, e.target.value)}
          name="address"
          disabled
        />
        <McInput
          label=""
          fit="small"
          value={editableAlternateLocation.siteOperator}
          input={(e: any) => handleInputChange(e.target.name, e.target.value)}
          name="siteOperator"
        >
          <SiteMandatoryLabel
            slot="label"
            label="Site Operator"
            isMandatory={true}
          />
        </McInput>
      </TextAndIconBody>
      <McTextAndIcon
        label={expandableSection[1].label}
        fit="large"
        icon={expandableSection[1].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(1);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[1].isExpanded}>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.warehouseType}
        >
          <McSelect
            label=""
            fit="small"
            value={editableAlternateLocation.wareHouseTypeId?.toString()}
            optionselected={(e: CustomEvent) =>
              handleInputChange("wareHouseTypeId", e.detail.value)
            }
            name="wareHouseTypeId"
            clearbutton
            clearbuttonclick={() => handleClearButtonClick("wareHouseTypeId")}
          >
            {warehouseTypeOptions.map((item: any) => {
              return (
                <McOption key={item.value} value={item.value}>
                  {item.label}
                </McOption>
              );
            })}
            <SiteMandatoryLabel
              slot="label"
              label="Warehouse Type"
              isMandatory={isMandatory(alternativeLocationKeys.warehouseType)}
            />
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.distanceToMajorPort}
        >
          <McInput
            label=""
            fit="small"
            type="number"
            suffix={"km"}
            value={editableAlternateLocation.distanceToMajorPort?.toString()}
            name="distanceToMajorPort"
            input={(e: any) => {
              handleInputChange(e.target.name, e.target.value);
            }}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Distance to major Port"
              isMandatory={isMandatory(
                alternativeLocationKeys.distanceToMajorPort
              )}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.distanceFromCurrentWarehouse}
        >
          <McInput
            label=""
            fit="small"
            type="number"
            suffix={"km"}
            value={editableAlternateLocation.distanceFromCurrentWarehouse?.toString()}
            name="distanceFromCurrentWarehouse"
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Distance from current warehouse"
              isMandatory={isMandatory(
                alternativeLocationKeys.distanceFromCurrentWarehouse
              )}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.wmsUsed}
        >
          <SelectBoolean
            label="WMS used"
            fit="small"
            value={editableAlternateLocation.wmsUsed}
            onChange={handleInputChange}
            name="wmsUsed"
            clearbutton
            onClearButtonClick={handleClearButtonClick}
          >
            <SiteMandatoryLabel
              slot="label"
              label="WMS used"
              isMandatory={isMandatory(alternativeLocationKeys.wmsUsed)}
            />
          </SelectBoolean>
          <McInput
            label=""
            fit="small"
            value={editableAlternateLocation.wmsType}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="wmsType"
            maxlength={50}
          >
            <SiteMandatoryLabel
              slot="label"
              label="WMS type"
              isMandatory={isMandatory(alternativeLocationKeys.wmsUsed)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
      <McTextAndIcon
        label={expandableSection[2].label}
        fit="large"
        icon={expandableSection[2].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(2);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[2].isExpanded}>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.businessContinuityTime}
        >
          <Box display="grid" gridTemplateColumns="1.5fr 0.5fr" gridGap="1rem">
            <Box>
              <McInput
                label=""
                fit="small"
                value={editableAlternateLocation.businessContinuityTime?.toString()}
                input={(e: any) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                type="number"
                name="businessContinuityTime"
              >
                <SiteMandatoryLabel
                  slot="label"
                  label="Business Continuity time"
                  isMandatory={isMandatory(
                    alternativeLocationKeys.businessContinuityTime
                  )}
                />
              </McInput>
            </Box>
            <Box>
              <McSelect
                label=""
                fit="small"
                value={editableAlternateLocation.businessContinuityTimeUnitId?.toString()}
                input={(e: any) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                type="number"
                name="businessContinuityTimeUnitId"
              >
                {timeIntervalOptions.map((item: any) => {
                  return (
                    <McOption key={item.value} value={item.value.toString()}>
                      {item.label}
                    </McOption>
                  );
                })}
                <SiteMandatoryLabel
                  slot="label"
                  label="Time interval unit"
                  isMandatory={isMandatory(
                    alternativeLocationKeys.businessContinuityTime
                  )}
                />
              </McSelect>
            </Box>
          </Box>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.fteCountForSupport}
        >
          <McInput
            label="FTE Count for support"
            fit="small"
            type="number"
            value={editableAlternateLocation.fteCountForSupport?.toString()}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="fteCountForSupport"
          >
            <SiteMandatoryLabel
              slot="label"
              label="FTE Count for support"
              isMandatory={isMandatory(
                alternativeLocationKeys.fteCountForSupport
              )}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.bcpStrategy}
        >
          <McSelect
            label=""
            fit="small"
            value={editableAlternateLocation.bcpStartegyId?.toString()}
            optionselected={(e: any) =>
              handleInputChange(e.target.name, e.detail.value)
            }
            name="bcpStartegyId"
            clearbutton
            clearbuttonclick={() => handleClearButtonClick("bcpStartegy")}
          >
            {bcpStrategyOptions.map((item: any) => {
              return (
                <McOption key={item.value} value={item.value}>
                  {item.label}
                </McOption>
              );
            })}
            <SiteMandatoryLabel
              slot="label"
              label="BCP Strategy"
              isMandatory={isMandatory(alternativeLocationKeys.bcpStrategy)}
            />
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.shiftDetails}
        >
          <McInput
            label=""
            fit="small"
            value={editableAlternateLocation.shiftDetails}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="shiftDetails"
            maxlength={50}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Shift details"
              isMandatory={isMandatory(alternativeLocationKeys.shiftDetails)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.itAppsAccessRequirements}
        >
          <McInput
            label=""
            fit="small"
            value={editableAlternateLocation.itAppsAccessRequirements}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="itAppsAccessRequirements"
            maxlength={500}
          >
            <SiteMandatoryLabel
              slot="label"
              label="IT Apps/Access requirements"
              isMandatory={isMandatory(
                alternativeLocationKeys.itAppsAccessRequirements
              )}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.constraintsImpactingContinuity}
        >
          <SelectBoolean
            label=""
            fit="small"
            value={editableAlternateLocation.constraintsImpactingContinuity}
            name="constraintsImpactingContinuity"
            onChange={handleInputChange}
            clearbutton
            onClearButtonClick={handleClearButtonClick}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Constraints impacting continuity"
              isMandatory={isMandatory(
                alternativeLocationKeys.constraintsImpactingContinuity
              )}
            />
          </SelectBoolean>
          <McTextarea
            label="Constraints impacting continuity Remarks"
            fit="small"
            value={
              editableAlternateLocation.constraintsImpactingContinuityRemarks
            }
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="constraintsImpactingContinuityRemarks"
            maxlength={500}
          />
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.activationPreparation}
        >
          <SelectBoolean
            label="Activation Preparation"
            fit="small"
            value={editableAlternateLocation.activationPreparation}
            name="activationPreparation"
            onChange={handleInputChange}
            clearbutton
            onClearButtonClick={handleClearButtonClick}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Activation Preparation"
              isMandatory={isMandatory(
                alternativeLocationKeys.activationPreparation
              )}
            />
          </SelectBoolean>
          <McTextarea
            label="Activation Preparation Remarks"
            fit="small"
            value={editableAlternateLocation.activationPreparationRemarks}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="activationPreparationRemarks"
            maxlength={500}
          />
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={alternativeLocationKeys.otherRemarks}
        >
          <McTextarea
            label="Other Remarks"
            fit="small"
            value={editableAlternateLocation.otherRemarks}
            input={(e: any) => handleInputChange(e.target.name, e.target.value)}
            name="otherRemarks"
            maxlength={500}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Other Remarks"
              isMandatory={isMandatory(alternativeLocationKeys.otherRemarks)}
            />
          </McTextarea>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
    </CommonModal>
  );
}
