import { ArcheType, SiteType } from "../../../models/Brand";

export const brandActions = {
  BRAND_NAME_CHANGE: "BRAND_NAME_CHANGE",
  SITE_TYPE_NAME_CHANGE: "SITE_TYPE_NAME_CHANGE",
  ARCHETYPE_NAME_CHANGE: "ARCHETYPE_NAME_CHANGE",
  ADD_SITE_TYPE: "ADD_SITE_TYPE",
  REMOVE_SITE_TYPE: "REMOVE_SITE_TYPE",
  ADD_ARCHETYPE: "ADD_ARCHETYPE",
  REMOVE_ARCHETYPE: "REMOVE_ARCHETYPE",
  SET_EDITABLE_BRAND: "SET_EDITABLE_BRAND",
  SET_DEFAULT: "SET_DEFAULT",
};

export const defaultBrand = {
  id: 0,
  businessBrandName: "",
  siteTypes: [],
};

export const brandReducer = (state: any, action: any) => {
  switch (action.type) {
    case brandActions.BRAND_NAME_CHANGE:
      return { ...state, businessBrandName: action.businessBrandName };
    case brandActions.SITE_TYPE_NAME_CHANGE: {
      let siteTypes = [...state.siteTypes];
      siteTypes[action.index] = {
        ...siteTypes[action.index],
        siteTypeName: action.siteTypeName,
      };
      return { ...state, siteTypes: siteTypes };
    }
    case brandActions.ARCHETYPE_NAME_CHANGE: {
      let siteTypesNew = [...state.siteTypes];
      let archeTypes = [...siteTypesNew[action.siteTypeIndex].archeTypes];
      archeTypes[action.archeTypeIndex] = {
        ...archeTypes[action.archeTypeIndex],
        archeTypeName: action.archeTypeName,
      };
      siteTypesNew[action.siteTypeIndex] = {
        ...siteTypesNew[action.siteTypeIndex],
        archeTypes: archeTypes,
      };
      return { ...state, siteTypes: siteTypesNew };
    }
    case brandActions.ADD_SITE_TYPE: {
      let siteTypesAdd = [
        ...state.siteTypes,
        {
          id: 0,
          siteTypeName: "",
          archeTypes: [
            {
              id: 0,
              archeTypeName: "",
            },
          ],
        },
      ];
      return { ...state, siteTypes: siteTypesAdd };
    }
    case brandActions.ADD_ARCHETYPE: {
      let siteTypesAddArchetype = [...state.siteTypes];
      let archetypesNew = [
        ...siteTypesAddArchetype[action.siteTypeIndex].archeTypes,
        {
          id: 0,
          archeTypeName: "",
        },
      ];
      siteTypesAddArchetype[action.siteTypeIndex] = {
        ...siteTypesAddArchetype[action.siteTypeIndex],
        archeTypes: archetypesNew,
      };
      return { ...state, siteTypes: siteTypesAddArchetype };
    }
    case brandActions.REMOVE_SITE_TYPE: {
      let siteTypesRemove = [...state.siteTypes];
      siteTypesRemove = siteTypesRemove.filter(
        (siteType: SiteType, index: number) => index !== action.siteTypeIndex
      );
      let newState = { ...state, siteTypes: siteTypesRemove };
      return newState;
    }
    case brandActions.REMOVE_ARCHETYPE: {
      let removeArchetypeSiteType = [...state.siteTypes];
      let removeArchetype =
        removeArchetypeSiteType[action.siteTypeIndex].archeTypes;
      removeArchetype = removeArchetype.filter(
        (archetype: ArcheType, index: number) => index !== action.archeTypeIndex
      );
      removeArchetypeSiteType[action.siteTypeIndex] = {
        ...removeArchetypeSiteType[action.siteTypeIndex],
        archeTypes: removeArchetype,
      };
      return { ...state, siteTypes: removeArchetypeSiteType };
    }
    case brandActions.SET_EDITABLE_BRAND:
      return action.brand;
    default:
      return state;
  }
};
