import { useContext, useEffect, useReducer, useState } from "react";
import useSite from "../../../hooks/useSite";
import {
  configureSiteCustomer,
  getSiteCustomers,
} from "../../../services/Site/Customer";
import Welcome from "../../Welcome";
import { toast, toastError } from "../../../components/common/toast";
import { CustomerAction, initialState, reducer } from "./reducers/SiteCustomer";
import Box from "../../../components/common/box";
import Heading from "../../../components/common/heading";
import { McButton, McSwitch, McTable } from "@maersk-global/mds-react-wrapper";
import {
  CustomerStatus,
  SiteCustomer,
  SiteCustomerKeys,
} from "../../../models/SiteCustomer";
import { CardKeyValueDisplay } from "../../../components/common/cardKeyValueDisplay";
import { DeleteIcon } from "../../../components/common/icons";
import { FlexGrowBox } from "../../../components/common/common.styles";
import ExpandedTableContent from "../../../components/common/expandedTableContent";
import { getSingleSiteConfiguration } from "../../../services/SiteConfiguration";
import { ConfigurationMapping } from "../../../models/AlternativeLocation";
import Notifications from "../../../components/common/notifications";
import ConfigurableComponentsRenderer from "../../../components/common/configurableItemsRenderer";
import AddEditSiteCustomer from "./modals/addEditSiteCustomer";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import DeleteModal from "../../../components/common/deleteModal";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import { ModeContext } from "../SiteBCM";
import { SiteModes } from "../../../constants/SiteBCM";
import { loadingIndicator } from "../../../components/common/loading";
import HorizontalLine from "../../../components/common/horizontalLine";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import Action from "../../../components/common/action";
import { chunkArray } from "../../../utils/utils";

export const customerKeys: SiteCustomerKeys = {
  customerName: "customer-name",
  customerService: "customer-service",
  concernCode: "concern-code",
  customerCode: "customer-code",
  typeOfCustomer: "type-of-customer",
  vertical: "vertical",
  keyClientDirector: "key-client-director",
  cpd: "cpd",
  cpm: "cpm",
  scdManager: "scd-manager",
  bcpRequirement: "bcp-requirement",
  bcpRequirementComment: "bcp-requirement-comment",
  bcpTestRequirement: "bcp-test-requirement",
  bcpTestRequirementComment: "bcp-test-requirement-comment",
  itRecoveryRequirement: "it-recovery-requirement",
  itRecoveryRequirementComment: "it-recovery-requirement-comment",
  bcTolerence: "bc-tolerence",
  incidentNotification: "incident-notification",
  incidentNotificationMethod: "incident-notification-method",
  otherBcRequirements: "other-bc-requirements",
  avgWeeklyInboundOutboundVolume: "avg-weekly-inbound-outbound-volume",
  volumeNomination: "volume-nomination",
  avgWeeklyVesselCalls: "avg-weekly-vessel-calls",
  commitmentServiceOrderFulfillmentLevel:
    "commitment-service-order-fulfillment-level",
  minBusinessContinuityLevel: "min-business-continuity-level",
  contractualLiability: "contractual-liability",
  customerContactName: "customer-contact-name",
  customerContactPhone: "customer-contact-phone",
  customerContactEmail: "customer-contact-email",
  customerContactJobTitle: "customer-contact-job-title",
};

export default function SiteCustomers({
  handleStatusChange,
  sectionId,
}: Readonly<{ handleStatusChange: () => void; sectionId: number }>) {
  const { site } = useSite();
  const mode = useContext(ModeContext);

  const [loading, setLoading] = useState(true);
  const [state, customerdispatch] = useReducer(reducer, initialState);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [expanded, setExpanded] = useState<any[]>([]);

  useEffect(() => {
    getAllKeyCustomers(site.id.toString(), site.versionId.toString());
  }, [site.id, site.versionId]);

  const getAllKeyCustomers = (siteId: string, versionId: string) => {
    getSingleSiteConfiguration(site.id, site.versionId, "key-customer")
      .then((response) => {
        if (response.length === 0) {
          customerdispatch({
            type: CustomerAction.SET_ISAPPLICABLE,
            payload: false,
          });
          setLoading(false);
        } else {
          customerdispatch({
            type: CustomerAction.SET_ISAPPLICABLE,
            payload: true,
          });
        }

        let mappedResponse: ConfigurationMapping = response.reduce(
          (acc, item) => {
            return {
              ...acc,
              [item.key]: item.configurationValue,
            };
          },
          {}
        );
        customerdispatch({
          type: CustomerAction.SET_CONFIGURATIONS,
          payload: mappedResponse,
        });
      })
      .catch((error) => {
        toastError(error.message);
      });

    getSiteCustomers(siteId, versionId)
      .then((response) => {
        if (response) {
          setLoading(false);
          let chunkedArray = chunkArray(response, 2);
          customerdispatch({
            type: CustomerAction.SET_CUSTOMER,
            payload: chunkedArray,
          });
          customerdispatch({
            type: CustomerAction.SET_CUSTOMERS_TABLE_DATA,
            payload: response,
          });
        }
      })
      .catch((err): void => {
        toast("error", err.message, "error");
        setLoading(false);
      });
  };
  const onAddNewCustomerClick = () => {
    customerdispatch({
      type: CustomerAction.SET_EDITABLE_CUSTOMER,
      payload: null,
    });
    customerdispatch({
      type: CustomerAction.SET_KEY_CUSTOMERS_MODAL_OPEN,
      payload: true,
    });
  };

  const onEditCustomerClick = (customer: SiteCustomer) => {
    customerdispatch({
      type: CustomerAction.SET_KEY_CUSTOMERS_MODAL_OPEN,
      payload: true,
    });
    customerdispatch({
      type: CustomerAction.SET_EDITABLE_CUSTOMER,
      payload: customer,
    });
  };

  const handleCustomerExpansion = (id: number) => {
    setExpanded((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onSaveCustomer = () => {
    getAllKeyCustomers(site.id.toString(), site.versionId.toString());
    handleStatusChange();
  };

  const handleDelete = (id: number, path: string) => {
    onDeleteEntity(
      dispatchDelete,
      id,
      "Site Cusomer",
      path,
      "sitekeycustomer",
      ""
    );
  };

  const SetShowTable = () => {
    customerdispatch({
      type: CustomerAction.SET_SHOW_TABLE,
      payload: !state.showtable,
    });
  };

  const handleSwitchChange = (id: number) => {
    const updatedcustomer = state.customersTableData.find(
      (customer: SiteCustomer) => customer.id === id
    );
    if (updatedcustomer) {
      let status: CustomerStatus = {
        id: updatedcustomer.id,
        isApplicable: !updatedcustomer.isApplicable,
      };
      configureSiteCustomer(status)
        .then((res) => {
          onSaveCustomer();
        })
        .catch((error) => {
          toastError(error.message);
        });
    }
  };

  const expandableControl = (id: number) => {
    return (
      <Box display="flex">
        <FlexGrowBox />
        <Box>
          <McButton
            icon="expand"
            appearance="neutral"
            variant="outlined"
            fit="small"
            padding="default"
            click={() => handleCustomerExpansion(id)}
          />
        </Box>
      </Box>
    );
  };

  const CustomerCard = ({
    customer,
    id,
  }: {
    customer: SiteCustomer;
    id: number;
  }) => {
    return (
      <>
        <Box display="flex">
          <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
            <McSwitch
              hidden={!customer.isGlobalKeyCustomer}
              change={(e: CustomEvent) => handleSwitchChange(id)}
              checked={customer.isApplicable}
              label=""
              disabled={mode === SiteModes.Read}
            />
            <strong>{customer.customerName}</strong>
          </Box>
          <FlexGrowBox />
          <Box display="flex" style={{ gap: "8px" }}>
            <SiteElementsRenderer mode={mode}>
              {customer.isApplicable && (
                <McButton
                  icon="pencil"
                  appearance="neutral"
                  variant="outlined"
                  fit="small"
                  click={() => {
                    onEditCustomerClick(customer);
                  }}
                />
              )}
              {!customer.isGlobalKeyCustomer ? (
                <McButton
                  appearance="neutral"
                  variant="outlined"
                  fit="small"
                  click={() => handleDelete(customer.id, customer.customerName)}
                >
                  <span slot="icon">
                    <DeleteIcon />
                  </span>
                </McButton>
              ) : null}
            </SiteElementsRenderer>
          </Box>
        </Box>
        <HorizontalLine />
        <strong>{customer.customerService}</strong>
        <ConfigurableComponentsRenderer
          configurations={state.configurations}
          configKey={customerKeys.concernCode}
        >
          <CardKeyValueDisplay
            label="Concern Code"
            value={customer.concernCode}
          />
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={state.configurations}
          configKey={customerKeys.customerCode}
        >
          <CardKeyValueDisplay
            label="Customer Code"
            value={customer.customerCode ?? ""}
          />
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={state.configurations}
          configKey={customerKeys.typeOfCustomer}
        >
          <CardKeyValueDisplay
            label="Type of Customer"
            value={customer.typeOfCustomer}
          />
        </ConfigurableComponentsRenderer>

        <ConfigurableComponentsRenderer
          configurations={state.configurations}
          configKey={customerKeys.vertical}
        >
          <CardKeyValueDisplay label="Vertical" value={customer.vertical} />
        </ConfigurableComponentsRenderer>
        {!expanded[id] && expandableControl(id)}
        <Box
          style={{
            display: expanded[id] ? "" : "none",
          }}
        >
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.keyClientDirector}
          >
            <CardKeyValueDisplay
              label="Key Client Director"
              value={customer.keyClientDirector}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.cpd}
          >
            <CardKeyValueDisplay label="CPD" value={customer.cpd} />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.cpm}
          >
            <CardKeyValueDisplay label="CPM" value={customer.cpm} />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.scdManager}
          >
            <CardKeyValueDisplay
              label="SCD Manager"
              value={customer.scdManager}
            />
          </ConfigurableComponentsRenderer>
          <br />
          <strong>REQUIREMENTS</strong>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.bcpRequirement}
          >
            <CardKeyValueDisplay
              label="BCP Requirement"
              value={customer.bcpRequirement ? "Yes" : "No"}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.bcpRequirementComment}
          >
            <CardKeyValueDisplay
              label="BCP Requirement Comment"
              value={customer.bcpRequirementComment}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.bcpTestRequirement}
          >
            <CardKeyValueDisplay
              label="BCP Test Requirement"
              value={customer.bcpTestRequirement ? "Yes" : "No"}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.bcpTestRequirementComment}
          >
            <CardKeyValueDisplay
              label="BCP Test Requirement Comment"
              value={customer.bcpTestRequirementComment}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.itRecoveryRequirement}
          >
            <CardKeyValueDisplay
              label="IT Recovery Requirement"
              value={customer.itRecoveryRequirement ? "Yes" : "No"}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.itRecoveryRequirementComment}
          >
            <CardKeyValueDisplay
              label="IT Recovery Requirement Comment"
              value={customer.itRecoveryRequirementComment}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.bcTolerence}
          >
            <CardKeyValueDisplay
              label="BC Tolerance"
              value={customer.bcToleranceValue}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.incidentNotification}
          >
            <CardKeyValueDisplay
              label="Incident Notification"
              value={customer.incidentNotificationValue}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.incidentNotificationMethod}
          >
            <CardKeyValueDisplay
              label="Incident Notification Method"
              value={customer.incidentNotificationMethod}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.otherBcRequirements}
          >
            <CardKeyValueDisplay
              label="Other BCP Requirement"
              value={customer.otherBCPRequirement}
            />
          </ConfigurableComponentsRenderer>
          <br />
          <strong>DATA</strong>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.avgWeeklyInboundOutboundVolume}
          >
            <CardKeyValueDisplay
              label="Avg Weekly Inbound/Outbound Volume"
              value={`${customer.avgWklyInboudOutBoundVolume ?? ""} ${customer.avgWklyInboundOutBoundVolumeUnit ?? ""}`}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.avgWeeklyVesselCalls}
          >
            <CardKeyValueDisplay
              label="Avg Weekly Vessel Calls"
              value={customer.avgWeeklyVesselCalls ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.commitmentServiceOrderFulfillmentLevel}
          >
            <CardKeyValueDisplay
              label="Commitment Service/Order fullfilment Level"
              value={customer.commitmentService ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.minBusinessContinuityLevel}
          >
            <CardKeyValueDisplay
              label="Min business Continuity Level"
              value={customer.minBusinessContinuityLevel ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.contractualLiability}
          >
            <CardKeyValueDisplay
              label="Contractual Liability associated to failure"
              value={customer.contractualLiabilityToFailure ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <br />
          <strong>CONTACT INFORMATION</strong>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.customerContactName}
          >
            <CardKeyValueDisplay
              label="Contact Person(Name)"
              value={customer.contactName ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.customerContactPhone}
          >
            <CardKeyValueDisplay
              label="Phone Number"
              value={customer.contactPhone ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.customerContactEmail}
          >
            <CardKeyValueDisplay
              label="Email"
              value={customer.contactEmail ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={state.configurations}
            configKey={customerKeys.customerContactJobTitle}
          >
            <CardKeyValueDisplay
              label="Job Title"
              value={customer.contactJobTitle ?? ""}
            />
          </ConfigurableComponentsRenderer>
          {expanded[id] && expandableControl(id)}
        </Box>
      </>
    );
  };

  const constructActionItems = (row: any) => {
    let items = [];

    mode === SiteModes.Write &&
      items.push({
        label: "Edit",
        onClick: () => {
          onEditCustomerClick(row);
        },
      });

    mode === SiteModes.Write &&
      !row.isGlobalKeyCustomer &&
      items.push({
        label: "Delete",
        onClick: () => handleDelete(row.id, row.customerName),
      });

    return items;
  };

  const displayTable = () => {
    return (
      <>
        <br />
        <McTable
          disableroundedcorners
          data={state.customersTableData}
          expand
          columns={[
            { id: "customerName", label: "Name" },
            { id: "customerService", label: "Service" },
            { id: "concernCode", label: "Concern Code" },
            { id: "customerCode", label: "Customer Code" },
            { id: "typeOfCustomer", label: "Type" },
            { id: "vertical", label: "Vertical" },
            { id: "id", label: "", sortDisabled: true },
          ]}
          fit="small"
        >
          {state.customersTableData.map((row: SiteCustomer) => (
            <>
              <div
                key={`${row.id}_customerName`}
                slot={`${row.id}_customerName`}
              >
                {row.isGlobalKeyCustomer ? (
                  <McSwitch
                    change={(e: CustomEvent) => {
                      handleSwitchChange(row.id);
                    }}
                    checked={row.isApplicable}
                    disabled={mode === SiteModes.Read}
                  >
                    <div
                      slot="label"
                      className="label"
                      style={{
                        color: !row.isApplicable
                          ? "var(--mds_brand_appearance_neutral_strong_background-color)"
                          : "",
                      }}
                    >
                      {row.customerName}
                    </div>
                  </McSwitch>
                ) : (
                  row.customerName
                )}
              </div>
              <div key={`${row.id}_id`} slot={`${row.id}_id`}>
                <div style={{ float: "right" }}>
                  {mode === SiteModes.Write
                    ? row.isApplicable && (
                        <Action items={constructActionItems(row)} />
                      )
                    : null}
                </div>
              </div>
              <div slot={`${row.id}_expanded`} style={{ maxWidth: "60%" }}>
                <Box
                  display="grid"
                  gridTemplateColumns="0.5fr 0.5fr 0.5fr 0.5fr"
                >
                  <Box>
                    <ExpandedTableContent
                      title="Key Client Director, KCD"
                      value={row.keyClientDirector}
                    />
                    <ExpandedTableContent
                      title="Client Program Director, CPD"
                      value={row.cpd}
                    />
                    <ExpandedTableContent
                      title="Client Program Manager, CPM"
                      value={row.cpm}
                    />
                    <ExpandedTableContent
                      title="Supply Chain Development, SCD"
                      value={row.scdManager}
                    />
                  </Box>
                  <Box>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcpRequirement}
                    >
                      <ExpandedTableContent
                        title="BCP Requirement"
                        value={row.bcpRequirement ? "Yes" : "No"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcpRequirementComment}
                    >
                      <ExpandedTableContent
                        title="BCP Requirement Comment"
                        value={row.bcpRequirementComment || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcpTestRequirement}
                    >
                      <ExpandedTableContent
                        title="BCP Test Requirement"
                        value={row.bcpTestRequirement ? "Yes" : "No"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcpTestRequirementComment}
                    >
                      <ExpandedTableContent
                        title="BCP Test Requirement Comment"
                        value={row.bcpTestRequirementComment || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.itRecoveryRequirement}
                    >
                      <ExpandedTableContent
                        title="IT Recovery Requirement"
                        value={row.itRecoveryRequirement ? "Yes" : "No"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.itRecoveryRequirementComment}
                    >
                      <ExpandedTableContent
                        title="IT Recovery Requirement Comment"
                        value={row.itRecoveryRequirementComment || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcTolerence}
                    >
                      <ExpandedTableContent
                        title="BC Tolerance Value"
                        value={row.bcToleranceValue || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.incidentNotificationMethod}
                    >
                      <ExpandedTableContent
                        title="Incident Notification Value"
                        value={row.incidentNotificationValue || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                  </Box>
                  <Box>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.bcTolerence}
                    >
                      <ExpandedTableContent
                        title="Incident Notification Method"
                        value={row.incidentNotificationMethod || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.otherBcRequirements}
                    >
                      <ExpandedTableContent
                        title="Other BCP Requirement"
                        value={row.otherBCPRequirement || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.avgWeeklyInboundOutboundVolume}
                    >
                      <ExpandedTableContent
                        title="Average Weekly Inbound/Outbound Volume"
                        value={row.avgWklyInboudOutBoundVolume || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.avgWeeklyVesselCalls}
                    >
                      <ExpandedTableContent
                        title="Average Weekly Vessel Calls"
                        value={row.avgWeeklyVesselCalls || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={
                        customerKeys.commitmentServiceOrderFulfillmentLevel
                      }
                    >
                      <ExpandedTableContent
                        title="Commitment Service/Order fullfilment level"
                        value={row.commitmentService || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.minBusinessContinuityLevel}
                    >
                      <ExpandedTableContent
                        title="Min business Continuity Level"
                        value={row.minBusinessContinuityLevel || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.contractualLiability}
                    >
                      <ExpandedTableContent
                        title="Contractual Liability associated to service failure"
                        value={row.contractualLiabilityToFailure || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                  </Box>
                  <Box>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.customerContactName}
                    >
                      <ExpandedTableContent
                        title="Contact Person(Name)"
                        value={row.contactName || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.customerContactPhone}
                    >
                      <ExpandedTableContent
                        title="Phone Number"
                        value={row.contactPhone || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.customerContactEmail}
                    >
                      <ExpandedTableContent
                        title="Email"
                        value={row.contactEmail || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                    <ConfigurableComponentsRenderer
                      configurations={state.configurations}
                      configKey={customerKeys.customerContactJobTitle}
                    >
                      <ExpandedTableContent
                        title="Job Title"
                        value={row.contactJobTitle || "N/A"}
                      />
                    </ConfigurableComponentsRenderer>
                  </Box>
                </Box>
              </div>
            </>
          ))}
        </McTable>
      </>
    );
  };

  const displayasCard = () => {
    return (
      <>
        {state.customers?.map((chunk: any, chunkIndex: number) => (
          <Box
            key={chunk}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="10px"
          >
            {chunk.map((customer: SiteCustomer) => (
              <Box
                key={customer.id}
                style={{
                  padding: "10px",
                  border:
                    "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
                  boxSizing: "border-box",
                  height: expanded[customer.id] ? "auto" : "250px",
                  overflow: "auto",
                  margin: "8px",
                }}
              >
                <CustomerCard
                  key={customer.id}
                  customer={customer}
                  id={customer.id}
                />
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  };

  const renderSiteCustomers = () => {
    return (
      <SiteElementLayout heading="Customers" sectionId={sectionId}>
        <SiteElementsRenderer mode={mode}>
          <>
            <Heading heading={""} subheading={""}>
              <McButton
                click={onAddNewCustomerClick}
                label="Add New Customer"
                fit="medium"
              />
            </Heading>
            <HorizontalLine />
          </>
        </SiteElementsRenderer>
        <Heading heading={""} subheading={""}>
          <McButton
            label={state.showtable ? "Show as Cards" : "Show as Table"}
            appearance="neutral"
            variant="outlined"
            fit="small"
            click={SetShowTable}
          />
        </Heading>
        {!state.showtable ? displayasCard() : displayTable()}
      </SiteElementLayout>
    );
  };

  const renderWelcome = () => {
    return (
      <Welcome
        heading={"Customers"}
        subHeading={
          "There are no Customers available for this site. Start adding one."
        }
        buttonLabel={"Add New Customer"}
        icon="plus"
        onButtonClick={onAddNewCustomerClick}
        isDisabled={mode === SiteModes.Read}
      />
    );
  };

  if (loading) return loadingIndicator;

  return (
    <>
      {state.isApplicable ? (
        <>
          <DeleteModal
            isOpen={deleteEntityState.isModalOpen}
            onSave={onSaveCustomer}
            deleteEntity={deleteEntityState.deleteEntity}
            onClose={() => {
              dispatchDelete({
                type: deleteActions.SET_DELETE_MODAL_CLOSE,
              });
              handleStatusChange();
            }}
          />
          <AddEditSiteCustomer
            isOpen={state.keyCustomersModalOpen}
            editableCustomer={state.editableCustomer}
            configurations={state.configurations}
            onSave={onSaveCustomer}
            onClose={() => {
              customerdispatch({
                type: CustomerAction.SET_KEY_CUSTOMERS_MODAL_OPEN,
                payload: false,
              });
              customerdispatch({
                type: CustomerAction.SET_EDITABLE_CUSTOMER,
                payload: {},
              });
            }}
          />
          {state.customers?.length === 0
            ? renderWelcome()
            : renderSiteCustomers()}
        </>
      ) : (
        <Notifications
          description={["This section is not applicable for your site."]}
          variant="warning"
        />
      )}
    </>
  );
}

export interface CustomerCardProps {
  customer: SiteCustomer;
  id: number;
  handleDelete: () => void;
  customerdispatch: (action: any) => void;
}
