export const processImpactActions = {
  SET_TYPE: "SET_TYPE",
  RESET_MODAL_STATE: "RESET_MODAL_STATE",
  SET_EDITABLE_PROCESS: "SET_EDITABLE_PROCESS",
  PROCESS_NAME_CHANGE: "PROCESS_NAME_CHANGE",
  ADD_PROCESS: "ADD_PROCESS",
  ADD_SUB_PROCESS_SUB_PROCESS: "ADD_SUB_PROCESS_SUB_PROCESS",
  ADD_SUB_PROCESS: "ADD_SUB_PROCESS",
  SET_SUBPROCESS_PROCESS_NAME_CHANGE: "SET_SUBPROCESS_PROCESS_NAME_CHANGE",
  SET_SUBPROCESS_SUBPROCESS_NAME_CHANGE:
    "SET_SUBPROCESS_SUBPROCESS_NAME_CHANGE",
  REMOVE_PROCESS: "REMOVE_PROCESS",
  REMOVE_SUB_PROCESS_SUB_PROCESS: "REMOVE_SUB_PROCESS_SUB_PROCESS",
};

export const processTypes = {
  process: "process",
  subprocess: "subprocess",
};

const typeOptions: any[] = [
  {
    id: processTypes.process,
    label: "Add only process",
    value: processTypes.process,
  },
  {
    id: processTypes.subprocess,
    label: "Add process and sub-process",
    value: processTypes.subprocess,
  },
];

const defaultProcessState = [
  {
    id: 0,
    name: "",
  },
];

const defaultSubProcessState = [
  {
    id: 0,
    name: "",
    subProcesses: [
      {
        id: 0,
        name: "",
      },
    ],
  },
];

export const defaultProcessImpactState = {
  id: 0,
  type: typeOptions[0].value,
  process: defaultProcessState,
  subProcess: defaultSubProcessState,
  isProcess: true,
  typeOptions: typeOptions,
};

export const processImpactReducer = (state: any, action: any) => {
  switch (action.type) {
    case processImpactActions.SET_TYPE:
      return {
        ...state,
        type: action.value,
        isProcess: action.value === processTypes.process,
      };
    case processImpactActions.ADD_PROCESS:
      return {
        ...state,
        process: [
          ...state.process,
          {
            id: 0,
            name: "",
          },
        ],
      };
    case processImpactActions.ADD_SUB_PROCESS_SUB_PROCESS: {
      let subProcess = [...state.subProcess];
      let subProcessNew = [
        ...subProcess[action.index].subProcesses,
        {
          id: 0,
          name: "",
        },
      ];
      subProcess[action.index] = {
        ...subProcess[action.index],
        subProcesses: subProcessNew,
      };
      return { ...state, subProcess: subProcess };
    }
    case processImpactActions.ADD_SUB_PROCESS:
      return {
        ...state,
        subProcess: [
          ...state.subProcess,
          {
            id: 0,
            name: "",
            subProcesses: [
              {
                id: 0,
                name: "",
              },
            ],
          },
        ],
      };
    case processImpactActions.PROCESS_NAME_CHANGE: {
      let process = [...state.process];
      process[action.index] = {
        ...process[action.index],
        name: action.value,
      };
      return { ...state, process: process };
    }
    case processImpactActions.SET_SUBPROCESS_PROCESS_NAME_CHANGE: {
      let subProcessNameChange = [...state.subProcess];
      subProcessNameChange[action.index] = {
        ...subProcessNameChange[action.index],
        name: action.value,
      };
      return { ...state, subProcess: subProcessNameChange };
    }
    case processImpactActions.SET_SUBPROCESS_SUBPROCESS_NAME_CHANGE: {
      let subProcessSubProcess = [...state.subProcess];
      let subProcessChanged = [
        ...subProcessSubProcess[action.processIndex].subProcesses,
      ];
      subProcessChanged[action.index] = {
        ...subProcessChanged[action.index],
        name: action.value,
      };
      subProcessSubProcess[action.processIndex] = {
        ...subProcessSubProcess[action.processIndex],
        subProcesses: subProcessChanged,
      };
      return { ...state, subProcess: subProcessSubProcess };
    }
    case processImpactActions.RESET_MODAL_STATE:
      return defaultProcessImpactState;
    case processImpactActions.SET_EDITABLE_PROCESS:
      if (state.isProcess) {
        return {
          ...state,
          process: action.value,
        };
      } else {
        return {
          ...state,
          subProcess: action.value,
        };
      }
    case processImpactActions.REMOVE_PROCESS:
      if (state.isProcess) {
        let process = [...state.process];
        let processesRemoved = process.filter(
          (process: any, index: number) => index !== action.index
        );
        return { ...state, process: processesRemoved };
      } else {
        let subProcess = [...state.subProcess];
        let subprocessesRemoved = subProcess.filter(
          (subprocess: any, index: number) => index !== action.index
        );
        return { ...state, subProcess: subprocessesRemoved };
      }
    case processImpactActions.REMOVE_SUB_PROCESS_SUB_PROCESS: {
      let subProcessRemoved = [...state.subProcess];
      let subProcessProcess = [
        ...subProcessRemoved[action.processIndex].subProcesses,
      ];
      let subProcessesRemoved = subProcessProcess.filter(
        (subprocess: any, index: number) => index !== action.subprocessIndex
      );
      subProcessRemoved[action.processIndex] = {
        ...subProcessRemoved[action.processIndex],
        subProcesses: subProcessesRemoved,
      };
      return { ...state, subProcess: subProcessRemoved };
    }
    default:
      return state;
  }
};
