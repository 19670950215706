import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SiteElementLayout from "../../components/common/siteElementLayout";
import Box from "../../components/common/box";
import { McInputTime, McNumberStepper } from "@maersk-global/mds-react-wrapper";
import {
  getOperationSchedule,
  saveOperationSchedules,
} from "../../services/Site/OperationalParameters";
import { loadingIndicator } from "../../components/common/loading";
import { Button } from "../../components/common/button";
import { toast } from "../../components/common/toast";
import { configuration } from "../../constants/Site";
import {
  OperationSchedule as OperationScheduleModel,
  SaveOperationSchedule,
  SaveOperationSchedules,
} from "../../models/OperationSchedule";
import Notifications from "../../components/common/notifications";
import useSite from "../../hooks/useSite";
import SiteElementsRenderer from "../../components/common/siteElementsRenderer";
import { ModeContext } from "./SiteBCM";
import { OperationalScheduleInfo } from "../../constants/SiteInfo";

export default function OperationSchedule({
  onSubmit,
  sectionId,
}: Readonly<{
  onSubmit: () => void;
  sectionId: number;
}>) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isApplicable, setIsApplicable] = useState<boolean>(false);
  const [operationSchedule, setOperationSchedule] = useState<
    OperationScheduleModel[]
  >([]);

  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  useEffect(() => {
    if (siteId > 0) {
      getSiteOperationalSchedule();
    }
  }, [siteId]);

  const getSiteOperationalSchedule = () => {
    getOperationSchedule(site.id, site.versionId)
      .then((response) => {
        setIsApplicable(response.isApplicable);
        if (response.isApplicable) {
          setOperationSchedule(response.operationSchedules);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const setAttribute = useCallback(
    (attribute: string, value: any, index: number) => {
      setOperationSchedule((prevSchedule) => {
        const updatedSchedule = [...prevSchedule];
        if (updatedSchedule[index].attributeValues === null) {
          updatedSchedule[index].attributeValues = {
            operatingHoursFrom: attribute === "operatingHoursFrom" ? value : "",
            operatingHoursTo: attribute === "operatingHoursTo" ? value : "",
            noOFShifts: attribute === "noOFShifts" ? value : 0,
          };
        } else {
          (updatedSchedule[index].attributeValues as any)[attribute] = value;
        }
        return updatedSchedule;
      });
    },
    []
  );

  const getSanitizedValues = (attributeValue: any) => {
    if (attributeValue === null) {
      return null;
    }
    return {
      operatingHoursFrom: getSplittedValue(attributeValue.operatingHoursFrom),
      operatingHoursTo: getSplittedValue(attributeValue.operatingHoursTo),
      noOFShifts: attributeValue.noOFShifts,
    };
  };

  const getSplittedValue = (value: string) => {
    if (value === "") {
      return "";
    }
    const [hours, minutes] = value.split(":");
    if (hours.length === 1) {
      return `0${hours}:${minutes || '00'}`;
    }
    return `${hours}:${minutes || '00'}`;
  };

  const save = useCallback(() => {
    let errorMessages = validate();
    if (errorMessages.length > 0) {
      toast("Error", errorMessages, "error");
      return;
    }

    let schedule = operationSchedule.map((item) => {
      return {
        attributeKey: item.key,
        attributeValues: getSanitizedValues(item.attributeValues),
      } as SaveOperationSchedule;
    });

    let saveOperationSchedulesModel: SaveOperationSchedules = {
      siteId: site.id,
      operationSchedules: schedule,
    };

    saveOperationSchedules(saveOperationSchedulesModel)
      .then(() => {
        toast("Success", "Operation schedule saved successfully", "success");
        onSubmit();
        getSiteOperationalSchedule();
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  }, [operationSchedule]);

  const validate = useCallback(() => {
    for (let item of operationSchedule) {
      let isMandatory = item.configurationValue === configuration.Mandatory;

      let attributeValues = item.attributeValues;

      if (isMandatory && attributeValues === null) {
        return `Operating hours and number of shifts for ${item.name} is mandatory.`;
      }
      if (
        isMandatory &&
        attributeValues !== null &&
        (attributeValues.operatingHoursFrom === "" ||
          attributeValues.operatingHoursTo === "")
      ) {
        return `Operating hours for ${item.name} is mandatory.`;
      } else if (
        attributeValues !== null &&
        isToGreaterThanFrom(
          attributeValues.operatingHoursFrom,
          attributeValues.operatingHoursTo
        )
      ) {
        return `Operating hours for ${item.name} is invalid. From cannot be greater than To.`;
      }
      if (isMandatory && attributeValues && attributeValues.noOFShifts < 0) {
        return `Number of shifts for ${item.name} is mandatory.`;
      }
      if (attributeValues && attributeValues.noOFShifts > 4) {
        return `Number of shifts for ${item.name} cannot be greater than 4.`;
      }
    }
    return "";
  }, [operationSchedule]);

  const isToGreaterThanFrom = (from: string, to: string) => {
    const fromTime = new Date(`1970-01-01T${from}:00Z`);
    const toTime = new Date(`1970-01-01T${to}:00Z`);

    let diff = toTime.getTime() - fromTime.getTime();

    if (diff < 0) {
      return true;
    }

    return false;
  };

  const disableKeypress = (e: React.KeyboardEvent) => {
    if (!(e.key === "Backspace")) {
      e.preventDefault();
    }
  };

  const calculateHours = (from: string, to: string) => {
    // Parse hours and minutes from time strings (e.g. "14:30")
    const [fromHours, fromMinutes] = from.split(':').map(Number);
    const [toHours, toMinutes] = to.split(':').map(Number);

    // Convert everything to minutes for easier calculation
    const fromTotalMinutes = fromHours * 60 + fromMinutes;
    const toTotalMinutes = toHours * 60 + toMinutes;

    // Calculate difference in hours, rounding up
    const diffMinutes = toTotalMinutes - fromTotalMinutes;
    return Math.ceil(diffMinutes / 60);
  };

  const buildOperationalSchedule = useCallback(() => {
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(3px, 1fr))"
        gap="1px"
      >
        <Box
          display="grid"
          gridTemplateRows="1fr 1fr 1fr 1fr 1fr"
          style={{ alignItems: "center" }}
        >
          <Box></Box>
          <Box>Operating Hours</Box>
          <Box></Box>
          <Box></Box>
          <Box>No of shifts</Box>
        </Box>
        {operationSchedule.map((item: any, index: number) => {
          let { name, configurationValue, attributeValues } = item;
          return configurationValue === configuration.Mandatory ||
            configurationValue === configuration.Optional ? (
            <Box key={index} display="grid" gridTemplateRows="1fr auto">
              <Box>
                {configurationValue === configuration.Mandatory && (
                  <span
                    style={{
                      color:
                        "var(--mds_brand_appearance_error_default_text-color)",
                    }}
                  >
                    *
                  </span>
                )}{" "}
                {name}
              </Box>
              <Box>
                <McInputTime
                  name="from"
                  input={(e: any) => {
                    setAttribute("operatingHoursFrom", e.target.value, index);
                  }}
                  value={attributeValues?.operatingHoursFrom ?? ""}
                  label=""
                  fit="medium"
                  placeholder="from"
                  hourstep={1}
                  minutestep={59}
                  onKeyDown={(e: any) => disableKeypress(e)}
                />
              </Box>
              <Box>
                <McInputTime
                  name="to"
                  input={(e: any) => {
                    setAttribute("operatingHoursTo", e.target.value, index);
                  }}
                  value={attributeValues?.operatingHoursTo ?? ""}
                  label=""
                  fit="medium"
                  placeholder="to"
                  hourstep={1}
                  minutestep={59}
                  onKeyDown={(e: any) => disableKeypress(e)}
                />
              </Box>
              <Box>
                <div
                  style={{
                    fontStyle: "italic",
                    fontWeight: "400",
                    fontSize: "12px",
                    color:
                      "var(--mds_brand_appearance_neutral_weak_text-color)",
                    padding: "5px 0",
                  }}
                >
                  {attributeValues?.operatingHoursFrom &&
                  attributeValues?.operatingHoursTo
                    ? `${calculateHours(
                        attributeValues?.operatingHoursFrom,
                        attributeValues?.operatingHoursTo
                      )} hour(s)`
                    : `0 hour(s)`}
                </div>
              </Box>
              <Box>
                <div style={{ minWidth: "100%" }}>
                  <McNumberStepper
                    name="numberOfShifts"
                    min="0"
                    max="4"
                    step="1"
                    width="20"
                    label=""
                    input={(e: any) =>
                      setAttribute("noOFShifts", e.target.value, index)
                    }
                    value={attributeValues?.noOFShifts ?? 0}
                    fit="medium"
                  />
                </div>
              </Box>
            </Box>
          ) : null;
        })}
      </Box>
    );
  }, [operationSchedule, setAttribute]);

  const operationalSchedule = useMemo(
    () => buildOperationalSchedule(),
    [operationSchedule, setAttribute]
  );

  if (loading) return loadingIndicator;

  if (!isApplicable) {
    return (
      <Notifications
        description={["Operation schedule is not applicable for your site."]}
        variant="warning"
      />
    );
  }

  return (
    <SiteElementLayout
      heading="Operation Schedule"
      sectionId={sectionId}
      popoverContent={OperationalScheduleInfo.Section_Heading}
    >
      {
        <>
          {operationSchedule.length > 0 ? (
            <>
              {operationalSchedule}
              <SiteElementsRenderer mode={mode}>
                <Box
                  display="flex"
                  style={{ alignItems: "flex-end", paddingTop: "10px" }}
                >
                  <Button fit="small" label="Save" click={() => save()} />
                </Box>
              </SiteElementsRenderer>
            </>
          ) : null}
        </>
      }
    </SiteElementLayout>
  );
}
